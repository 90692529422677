var render = function render(){var _vm=this,_c=_vm._self._c;return (
    (_vm.relatorio.Vacinas != null && _vm.relatorio.Vacinas?.length != 0) ||
    (_vm.relatorio.Pediluvio != null && _vm.relatorio.Pediluvio?.length != 0) ||
    (_vm.relatorio.Vermifucacao != null && _vm.relatorio.Vermifucacao?.length != 0) ||
    (_vm.relatorio.ControleCarrapatos != null &&
      _vm.relatorio.ControleCarrapatos?.length != 0) ||
    _vm.relatorio.OutrasRecomendacoesManejoSanitario != '' ||
    (_vm.relatorio.ImagensManejoSanitario != null &&
      _vm.relatorio.ImagensManejoSanitario?.length) ||
    _vm.relatorio.SanitarioObs ||
    _vm.relatorio.Step11Atividade1 ||
    _vm.relatorio.Step11Atividade2 ||
    _vm.relatorio.Step11VacinacaoEspecifica ||
    _vm.relatorio.Step11VacinacaoEspecificaText ||
    _vm.relatorio.Step11ControleEspecifico ||
    _vm.relatorio.Step11ControleEspecificoText ||
    _vm.relatorio.Step11OutrasAtividades ||
    _vm.relatorio.Step11OutrasAtividadesText ||
    (_vm.relatorio.sanitario &&
      (_vm.relatorio.sanitario.Vacinas ||
        _vm.relatorio.sanitario.Pediluvio ||
        _vm.relatorio.sanitario.Vermifugacao ||
        _vm.relatorio.sanitario.ControleCarrapatos ||
        _vm.relatorio.sanitario.Obs))
  )?_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Manejo sanitário")]),_c('v-card-text',[(
        _vm.relatorio.Step11Atividade1 ||
        _vm.relatorio.Step11Atividade2 ||
        _vm.relatorio.Step11VacinacaoEspecifica ||
        _vm.relatorio.Step11VacinacaoEspecificaText ||
        _vm.relatorio.Step11ControleEspecifico ||
        _vm.relatorio.Step11ControleEspecificoText ||
        _vm.relatorio.Step11OutrasAtividades ||
        _vm.relatorio.Step11OutrasAtividadesText ||
        _vm.relatorio.Vacinas ||
        _vm.relatorio.Pediluvio ||
        _vm.relatorio.Vermifucacao ||
        _vm.relatorio.ControleCarrapatos ||
        _vm.relatorio.OutrasRecomendacoesManejoSanitario ||
        _vm.relatorio.SanitarioObs
      )?_c('v-row',{staticClass:"pa-3"},[(_vm.relatorio.Vacinas != null && _vm.relatorio.Vacinas?.length != 0)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Vacinas ")]),_vm._v(" "),_c('br'),_vm._l((_vm.relatorio.Vacinas),function(vacina,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(_vm.relatorio.Vacinas)?_c('span',[_c('strong',[_vm._v("Nome: ")]),_vm._v(_vm._s(vacina.msMomeVacina + ' ')),_c('br'),_c('strong',[_vm._v("Dosagem: ")]),_vm._v(_vm._s(vacina.msDosagemVacina + ' ')),_c('br'),_c('strong',[_vm._v("Categoria: ")]),_vm._v(_vm._s(vacina.msCategoriaVacina + ' '))]):_c('span',[_vm._v(" "+_vm._s(vacina)+" ")])])})],2):_vm._e(),(_vm.relatorio.Pediluvio != null && _vm.relatorio.Pediluvio?.length != 0)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Manter utilização do pedilúvio ")]),_vm._v(" "),_c('br'),_vm._l((_vm.relatorio.Pediluvio),function(pediluvio,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(_vm.relatorio.Pediluvio)?_c('span',[_c('strong',[_vm._v("Nome do produto: ")]),_vm._v(_vm._s(pediluvio.msNomeProdutoPediluvio + ' ')),_c('br'),_c('strong',[_vm._v("Frequência: ")]),_vm._v(_vm._s(pediluvio.msFrequenciaPediluvio + ' ')),_c('br'),_c('strong',[_vm._v("Concentração: ")]),_vm._v(_vm._s(pediluvio.msConcentracaoPediluvio + ' '))]):_c('span',[_vm._v(" "+_vm._s(pediluvio)+" ")])])})],2):_vm._e(),(
          _vm.relatorio.Vermifucacao != null && _vm.relatorio.Vermifucacao.length != 0
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Vermifugação ")]),_vm._v(" "),_c('br'),_vm._l((_vm.relatorio.Vermifucacao),function(vermifugo,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(_vm.relatorio.Vermifucacao)?_c('span',[_c('strong',[_vm._v("Nome do produto: ")]),_vm._v(_vm._s(vermifugo.msNomeProdutoVermifugacao + ' ')),_c('br'),_c('strong',[_vm._v("Dosagem: ")]),_vm._v(_vm._s(vermifugo.msDosagemVermifugacao + ' ')),_c('br'),_c('strong',[_vm._v("Via de aplicação: ")]),_vm._v(_vm._s(vermifugo.msViaAplicacaoVermifugacao + ' '))]):_c('span',[_vm._v(" "+_vm._s(vermifugo)+" ")])])})],2):_vm._e(),(
          _vm.relatorio.ControleCarrapatos != null &&
          _vm.relatorio.Vermifucacao?.length != 0
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Carrapaticidas ")]),_vm._v(" "),_c('br'),_vm._l((_vm.relatorio.ControleCarrapatos),function(carrapaticida,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(_vm.relatorio.ControleCarrapatos)?_c('span',[_c('strong',[_vm._v("Nome do produto: ")]),_vm._v(_vm._s(carrapaticida.msNomeProdutoCarrapaticida + ' ')),_c('br'),_c('strong',[_vm._v("Dosagem: ")]),_vm._v(_vm._s(carrapaticida.msDosagemCarrapaticida + ' ')),_c('br'),_c('strong',[_vm._v("Via de aplicação: ")]),_vm._v(_vm._s(carrapaticida.msViaAplicacaoCarrapaticida + ' '))]):_c('span',[_vm._v(" "+_vm._s(carrapaticida)+" ")])])})],2):_vm._e(),(
          _vm.relatorio.Step11Atividade1 ||
          _vm.relatorio.Step11Atividade2 ||
          _vm.relatorio.Step11VacinacaoEspecifica ||
          _vm.relatorio.Step11ControleEspecifico ||
          _vm.relatorio.Step11OutrasAtividades
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Atividades realizadas")]),(
            _vm.relatorio.Step11Atividade1 == '1' &&
            _vm.relatorio.Step11Atividade1Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Implantação do calendário sanitário: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step11Atividade1Text || '-'))])]):_vm._e(),(
            _vm.relatorio.Step11Atividade2 == '1' &&
            _vm.relatorio.Step11Atividade2Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Acompanhamento do calendário sanitário: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step11Atividade2Text || '-'))])]):_vm._e(),(
            _vm.relatorio.Step11VacinacaoEspecifica == '1' &&
            _vm.relatorio.Step11VacinacaoEspecificaText != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Realização da vacinação:")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step11VacinacaoEspecificaText || '-'))])]):_vm._e(),(
            _vm.relatorio.Step11ControleEspecifico == '1' &&
            _vm.relatorio.Step11ControleEspecificoText != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Planos de ação para controle de doenças: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step11ControleEspecificoText || '-'))])]):_vm._e(),(_vm.relatorio.Step11OutrasAtividades)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras Atividades: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step11OutrasAtividadesText || '-'))])]):_vm._e()],1):_vm._e(),(_vm.relatorio.OutrasRecomendacoesManejoSanitario != '')?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras recomendações: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.OutrasRecomendacoesManejoSanitario || '-'))])]):_vm._e(),(_vm.relatorio.SanitarioObs)?_c('v-col',[_c('span',{staticClass:"key-title"},[_vm._v("Atividade não realizada ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.SanitarioObs || '-'))])]):_vm._e()],1):(
        _vm.relatorio.sanitario &&
        (_vm.relatorio.sanitario.Vacinas ||
          _vm.relatorio.sanitario.Pediluvio ||
          _vm.relatorio.sanitario.Vermifugacao ||
          _vm.relatorio.sanitario.ControleCarrapatos ||
          _vm.relatorio.sanitario.Obs)
      )?_c('v-row',{staticClass:"pa-3"},[(_vm.relatorio.sanitario.Vacinas)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Vacinas ")]),_vm._v(" "),_c('br'),_vm._l((_vm.maskIdentify(
            _vm.relatorio.sanitario.Vacinas,
            'Nenhuma vacina inserida',
          )),function(vacina,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(_vm.relatorio.sanitario.Vacinas)?_c('span',[_c('strong',[_vm._v("Nome: ")]),_vm._v(_vm._s(vacina[0] + ' ')),_c('br'),_c('strong',[_vm._v("Dosagem: ")]),_vm._v(_vm._s(vacina[1] + ' ')),_c('br'),_c('strong',[_vm._v("Categoria: ")]),_vm._v(_vm._s(vacina[2] + ' '))]):_c('span',[_vm._v(" "+_vm._s(vacina)+" ")])])})],2):_vm._e(),(_vm.relatorio.sanitario.Pediluvio)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Manter utilização do pedilúvio ")]),_vm._v(" "),_c('br'),_vm._l((_vm.maskIdentify(
            _vm.relatorio.sanitario.Pediluvio,
            'Nenhum produto inserido',
          )),function(pediluvio,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(_vm.relatorio.sanitario.Pediluvio)?_c('span',[_c('strong',[_vm._v("Nome do produto: ")]),_vm._v(_vm._s(pediluvio[0] + ' ')),_c('br'),_c('strong',[_vm._v("Frequência: ")]),_vm._v(_vm._s(pediluvio[1] + ' ')),_c('br'),_c('strong',[_vm._v("Concentração: ")]),_vm._v(_vm._s(pediluvio[2] + ' '))]):_c('span',[_vm._v(" "+_vm._s(pediluvio)+" ")])])})],2):_vm._e(),(_vm.relatorio.sanitario.Vermifugacao)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Vermifugação ")]),_vm._v(" "),_c('br'),_vm._l((_vm.maskIdentify(
            _vm.relatorio.sanitario.Vermifugacao,
            'Nenhum produto inserido',
          )),function(vermifugo,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(_vm.relatorio.sanitario.Vermifugacao)?_c('span',[_c('strong',[_vm._v("Nome do produto: ")]),_vm._v(_vm._s(vermifugo[0] + ' ')),_c('br'),_c('strong',[_vm._v("Dosagem: ")]),_vm._v(_vm._s(vermifugo[1] + ' ')),_c('br'),_c('strong',[_vm._v("Via de aplicação: ")]),_vm._v(_vm._s(vermifugo[2] + ' '))]):_c('span',[_vm._v(" "+_vm._s(vermifugo)+" ")])])})],2):_vm._e(),(_vm.relatorio.sanitario.ControleCarrapatos)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Carrapaticidas ")]),_vm._v(" "),_c('br'),_vm._l((_vm.maskIdentify(
            _vm.relatorio.sanitario.ControleCarrapatos,
            'Nenhum produto inserido',
          )),function(carrapaticida,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(_vm.relatorio.sanitario.ControleCarrapatos)?_c('span',[_c('strong',[_vm._v("Nome do produto: ")]),_vm._v(_vm._s(carrapaticida[0] + ' ')),_c('br'),_c('strong',[_vm._v("Dosagem: ")]),_vm._v(_vm._s(carrapaticida[1] + ' ')),_c('br'),_c('strong',[_vm._v("Via de aplicação: ")]),_vm._v(_vm._s(carrapaticida[2] + ' '))]):_c('span',[_vm._v(" "+_vm._s(carrapaticida)+" ")])])})],2):_vm._e(),(_vm.relatorio.sanitario.Obs)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras recomendações: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.sanitario.Obs || '-'))])]):_vm._e()],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }