<template>
  <v-card
    v-if="
      relatorio.CotacaoInsumoVolumoso ||
      relatorio.ColetaDoSolo ||
      relatorio.AmostrasAnalise ||
      relatorio.LaudoAnalise ||
      relatorio.RecomendacaoVolumoso ||
      relatorio.PlanejamentoVolumosoObs ||
      relatorio.Step9Atividade1 ||
      relatorio.Step9Atividade2 ||
      relatorio.Step9Atividade3 ||
      relatorio.Step9Atividade4 ||
      relatorio.Step9Atividade5 ||
      relatorio.Step9OutrasAtividades ||
      relatorio.Step9OutrasAtividadesText
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left"
      >Planejamento de volumoso</v-card-title
    >
    <v-card-text>
      <v-row v-if="relatorio.PlanejamentoVolumoso" class="pa-3">
        <v-col
          v-if="
            relatorio.Step9Atividade1 ||
            relatorio.Step9Atividade2 ||
            relatorio.Step9Atividade3 ||
            relatorio.Step9Atividade4 ||
            relatorio.Step9Atividade5 ||
            relatorio.Step9OutrasAtividades
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Atividades realizadas</span>
          <v-col
            v-if="
              relatorio.Step9Atividade1 == '1' &&
              relatorio.Step9Atividade1Text != ''
            "
            cols="12"
          >
            <span class="value-title">Evolução do rebanho: </span>
            <br />
            <span class="value-title">{{
              relatorio.Step9Atividade1Text || '-'
            }}</span>
          </v-col>

          <v-col
            v-if="
              relatorio.Step9Atividade2 == '1' &&
              relatorio.Step9Atividade2Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Levantamento do estoque atual de volumoso:
            </span>
            <br />
            <span class="value-title">{{
              relatorio.Step9Atividade2Text || '-'
            }}</span>
          </v-col>

          <v-col
            v-if="
              relatorio.Step9Atividade3 == '1' &&
              relatorio.Step9Atividade3Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Levantamento do tamanho das áreas para produção de volumoso:
            </span>
            <br />
            <span class="value-title">{{
              relatorio.Step9Atividade3Text || '-'
            }}</span>
          </v-col>

          <v-col
            v-if="
              relatorio.Step9Atividade4 == '1' &&
              relatorio.Step9Atividade4Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Coleta de solo das áreas de plantio para análise:
            </span>
            <br />
            <span class="value-title">{{
              relatorio.Step9Atividade4Text || '-'
            }}</span>
          </v-col>

          <v-col
            v-if="
              relatorio.Step9Atividade5 == '1' &&
              relatorio.Step9Atividade5Text != ''
            "
            cols="12"
          >
            <span class="value-title">Plano alimentar: </span>
            <br />
            <span class="value-title">{{
              relatorio.Step9Atividade5Text || '-'
            }}</span>
          </v-col>

          <v-col
            v-if="
              relatorio.Step9OutrasAtividades != '' &&
              relatorio.Step9OutrasAtividades != '0'
            "
            cols="12"
          >
            <span class="key-title">Outras Atividades: </span> <br />
            <span class="value-title">{{
              relatorio.Step9OutrasAtividadesText || '-'
            }}</span>
          </v-col>
        </v-col>
        <v-col
          v-if="
            relatorio.CotacaoInsumoVolumoso ||
            relatorio.AmostrasAnalise ||
            relatorio.LaudoAnalise ||
            relatorio.RecomendacaoVolumoso ||
            relatorio.AmostrasAnalise ||
            relatorio.RecomendacaoVolumoso
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Recomendações:</span>
          <v-col v-if="relatorio.CotacaoInsumoVolumoso" cols="12">
            <span class="value-title">
              Fazer cotação de insumos para plantio
            </span>
          </v-col>
          <v-col v-if="relatorio.ColetaDoSolo" cols="12">
            <span class="value-title"
              >Realizar coleta de solo das áreas de plantio para análise
            </span>
          </v-col>
          <v-col v-if="relatorio.AmostrasAnalise" cols="12">
            <span class="value-title">Enviar amostras para análise </span>
          </v-col>
          <v-col v-if="relatorio.LaudoAnalise" cols="12">
            <span class="value-title"
              >Enviar o laudo da análise assim que estiver pronto
            </span>
          </v-col>
          <v-col v-if="relatorio.RecomendacaoVolumoso" cols="12">
            <span class="key-title">Outras recomendações: </span> <br />
            <span class="value-title">{{
              relatorio.RecomendacaoVolumoso || '-'
            }}</span>
          </v-col>
        </v-col>
      </v-row>
      <v-row v-else justify="center">
        <v-col>
          <span class="key-title">Atividade não realizada </span> <br />
          <span class="value-title">{{
            relatorio.PlanejamentoVolumosoObs || '-'
          }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PlanejamentoVolumoso',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    maskIdentify(raw) {
      return raw ? raw.split('|') : ['Nenhum insumo identificado']
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
  line-height: 40px;
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
