<template>
  <v-card
    v-if="
      ((relatorio.CriaRecria || relatorio.CriaRecriaObs) &&
        (relatorio.Step12Atividade1 ||
          relatorio.Step12Atividade2 ||
          relatorio.Step12Atividade3 ||
          relatorio.Step12Atividade4 ||
          relatorio.Step12ControleEspecifico ||
          relatorio.Step12ControleEspecificoText ||
          relatorio.Step12OutrasAtividades ||
          relatorio.Step12OutrasAtividadesText ||
          (relatorio.NovilhasComPeso != null &&
            relatorio.NovilhasComPeso.length) ||
          (relatorio.BezerrasDesmamar != null &&
            relatorio.BezerrasDesmamar.length) ||
          relatorio.PesagemRecria ||
          relatorio.OutrasRecomendacoesCriaRecria != '' ||
          (relatorio.ImagensAcompanhamentoCriaRecria != null &&
            relatorio.ImagensAcompanhamentoCriaRecria.length) ||
          relatorio.CriaRecriaObs)) ||
      (relatorio.criarecria &&
        (relatorio.criarecria.NovilhasComPeso ||
          relatorio.criarecria.BezerrasDesmamar ||
          relatorio.PesagemRecria ||
          relatorio.criarecria.Obs))
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left"
      >Acompanhamento da cria/recria</v-card-title
    >
    <v-card-text>
      <v-row
        v-if="
          (relatorio.CriaRecria || relatorio.CriaRecriaObs) &&
          (relatorio.Step12Atividade1 ||
            relatorio.Step12Atividade2 ||
            relatorio.Step12Atividade3 ||
            relatorio.Step12Atividade4 ||
            relatorio.Step12ControleEspecifico ||
            relatorio.Step12ControleEspecificoText ||
            relatorio.Step12OutrasAtividades ||
            relatorio.Step12OutrasAtividadesText ||
            relatorio.NovilhasComPeso != null ||
            relatorio.BezerrasDesmamar != null ||
            relatorio.PesagemRecria ||
            relatorio.OutrasRecomendacoesCriaRecria != '' ||
            relatorio.CriaRecriaObs)
        "
        class="pa-3"
      >
        <v-col
          v-if="
            relatorio.NovilhasComPeso != null &&
            relatorio.NovilhasComPeso.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Novilhas aptas à inseminação </span> <br />
          <v-chip
            v-for="(animal, index) in relatorio.NovilhasComPeso"
            :key="index"
            class="ma-1"
          >
            {{ animal.acrNomeOuBrinco }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.BezerrasDesmamar != null &&
            relatorio.BezerrasDesmamar.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Bezerras a desmamar </span> <br />
          <v-chip
            v-for="(animal, index) in relatorio.BezerrasDesmamar"
            :key="index"
            class="ma-1"
          >
            {{ animal.acrBezerrasDesmamarNomeOuBrinco }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.Step12Atividade1 ||
            relatorio.Step12Atividade2 ||
            relatorio.Step12Atividade3 ||
            relatorio.Step12Atividade4 ||
            relatorio.Step12ControleEspecifico ||
            relatorio.Step12ControleEspecificoText ||
            relatorio.Step12OutrasAtividades ||
            relatorio.Step12OutrasAtividadesText
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Atividades realizadas</span>
          <v-col
            v-if="
              relatorio.Step12Atividade1 == '1' &&
              relatorio.Step12Atividade1Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Acompanhamento dos procedimentos de cria das bezerras (rotina do
              bezerreiro):
            </span>
            <br />
            <span class="value-title">{{
              relatorio.Step12Atividade1Text || '-'
            }}</span>
          </v-col>
          <v-col
            v-if="
              relatorio.Step12Atividade2 == '1' &&
              relatorio.Step12Atividade2Text != ''
            "
            cols="12"
          >
            <span class="value-title">Vistoria dos piquetes de recria: </span>
            <br />
            <span class="value-title">{{
              relatorio.Step12Atividade2Text || '-'
            }}</span>
          </v-col>
          <v-col
            v-if="
              relatorio.Step12Atividade3 == '1' &&
              relatorio.Step12Atividade3Text != ''
            "
            cols="12"
          >
            <span class="value-title">Pesagem dos animais: </span>
            <br />
            <span class="value-title">{{
              relatorio.Step12Atividade3Text || '-'
            }}</span>
          </v-col>

          <v-col
            v-if="
              relatorio.Step12Atividade4 == '1' &&
              relatorio.Step12Atividade4Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Discussão dos indicadores de cria e recria de bezerras:
            </span>
            <br />
            <span class="value-title">{{
              relatorio.Step12Atividade4Text || '-'
            }}</span>
          </v-col>

          <v-col
            v-if="
              relatorio.Step12ControleEspecifico == '1' &&
              relatorio.Step12ControleEspecificoText != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Planos de ação para controle de doenças específicas de bezerras:
            </span>
            <br />
            <span class="value-title">{{
              relatorio.Step12ControleEspecificoText || '-'
            }}</span>
          </v-col>
          <v-col
            v-if="
              relatorio.Step12OutrasAtividades != '' &&
              relatorio.Step12OutrasAtividades != '0'
            "
            cols="12"
          >
            <span class="key-title">Outras Atividades: </span> <br />
            <span class="value-title">{{
              relatorio.Step12OutrasAtividadesText || '-'
            }}</span>
          </v-col>
        </v-col>
        <v-col v-if="relatorio.PesagemRecria" cols="12" class="col-bordered">
          <span class="key-title">Recomendações:</span>
          <v-col v-if="relatorio.PesagemRecria != ''" cols="12">
            <span class="value-title"
              >Realizar pesagem mensal dos animais em recria para acompanhar
              ganho de peso
            </span>
          </v-col>
          <v-col v-if="relatorio.OutrasRecomendacoesCriaRecria != ''" cols="12">
            <span class="key-title">Outras recomendações: </span> <br />
            <span class="value-title">{{
              relatorio.OutrasRecomendacoesCriaRecria || '-'
            }}</span>
          </v-col>
        </v-col>
        <v-col v-if="relatorio.CriaRecriaObs">
          <span class="key-title">Atividade não realizada </span> <br />
          <span class="value-title">{{ relatorio.CriaRecriaObs || '-' }}</span>
        </v-col>
      </v-row>
      <v-row
        v-else-if="
          (relatorio.criarecria &&
            (relatorio.criarecria.NovilhasComPeso ||
              relatorio.criarecria.BezerrasDesmamar ||
              relatorio.PesagemRecria ||
              relatorio.criarecria.Obs)) ||
          relatorio.CriaRecriaObs
        "
        class="pa-3"
      >
        <v-col
          v-if="relatorio.criarecria.NovilhasComPeso"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Novilhas aptas à inseminação </span> <br />
          <v-chip
            v-for="(animal, index) in maskIdentify(
              relatorio.criarecria.NovilhasComPeso,
            )"
            :key="index"
            class="ma-1"
          >
            {{ animal }}
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.criarecria.BezerrasDesmamar"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Bezerras a desmamar </span> <br />
          <v-chip
            v-for="(animal, index) in maskIdentify(
              relatorio.criarecria.BezerrasDesmamar,
            )"
            :key="index"
            class="ma-1"
          >
            {{ animal }}
          </v-chip>
        </v-col>
        <v-col v-if="relatorio.PesagemRecria" cols="12" class="col-bordered">
          <span class="key-title"
            >Realizar pesagem mensal dos animais em recria para acompanhar ganho
            de peso
          </span>
        </v-col>
        <v-col v-if="relatorio.criarecria.Obs" cols="12" class="col-bordered">
          <span class="key-title">Outras recomendações: </span> <br />
          <span class="value-title">{{ relatorio.criarecria.Obs || '-' }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CriaRecria',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    maskIdentify(raw) {
      return raw ? raw.split('|') : ['Nenhum animal identificado']
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
  line-height: 40px;
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
