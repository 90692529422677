<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left"
      >Próxima visita e assinatura</v-card-title
    >
    <v-card-text>
      <v-row class="pa-3">
        <v-col cols="12" class="col-bordered">
          <span class="key-title">Data da próxima visita: </span> <br />
          <span
            v-if="relatorio.AssinaturaProdutorOuResponsavel"
            class="value-title"
            >{{ relatorio.DataProximaVisita }}</span
          >
          <span v-else class="value-title">
            <span v-if="relatorio.DataProximaVisita == null">-</span>
            <span v-else>
              {{ moment(relatorio.DataProximaVisita).format('DD/MM/YYYY') }}
            </span>
          </span>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          xl="6"
          class="col-bordered"
          style="text-align: center"
        >
          <span
            v-if="relatorio.AssinaturaProdutorOuResponsavel"
            class="key-title"
          >
            Assinatura
            {{
              relatorio.AssinaturaProdutorOuResponsavel === '2'
                ? 'do responsável'
                : 'do(a) produtor(a)'
            }}
          </span>
          <span v-else class="key-title">
            Assinaturas
            {{
              relatorio.assinaturas.NomeResponsavel
                ? 'do responsável'
                : 'do(a) produtor(a)'
            }}
          </span>
          <br />
          <br />
          <img
            v-if="relatorio.AssinaturaProdutorOuResponsavel"
            :src="
              relatorio.AssinaturaProdutorOuResponsavel === '1'
                ? relatorio.AssinaturaProdutor
                : relatorio.AssinaturaResponsavel
            "
            alt="Não foi assinado!!!"
            width="auto"
            height="300px"
            class="assinatura"
          />

          <img
            v-else
            :src="relatorio.assinaturas.AssinaturaProdutor"
            alt="Erro ao carregar assinatura"
            width="auto"
            height="300px"
            class="assinatura"
            style="display: block; margin-left: auto; margin-right: auto"
          />
          <br />
          <span
            v-if="relatorio.AssinaturaProdutorOuResponsavel"
            class="key-title"
          >
            {{
              relatorio.AssinaturaProdutorOuResponsavel === '2'
                ? relatorio.NomeResponsavel
                : relatorio.fazenda.produtor.Nome
            }}
          </span>
          <span v-else class="key-title">
            {{
              relatorio.assinaturas.NomeResponsavel
                ? relatorio.assinaturas.NomeResponsavel
                : relatorio.fazenda.produtor.Nome
            }}
          </span>
        </v-col>
        <v-col
          v-if="relatorio.AssinaturaProdutorOuResponsavel"
          cols="12"
          lg="6"
          xl="6"
          class="col-bordered"
          style="text-align: center"
        >
          <span v-if="relatorio.AssinaturaConsultor" class="key-title">
            Assinatura consultor(a)
            <br />
            <img
              :src="relatorio.AssinaturaConsultor"
              alt="Não foi assinado!!!"
              width="auto"
              height="300px"
              class="assinatura"
            />
            <br />
            <span class="key-title">
              {{ relatorio.consultor.pessoafisica.Nome }}
            </span>
          </span>
        </v-col>
        <v-col
          v-else
          cols="12"
          lg="6"
          xl="6"
          class="col-bordered"
          style="text-align: center"
        >
          <span
            v-if="relatorio.assinaturas.AssinaturaConsultor"
            class="key-title"
          >
            Assinatura consultor(a)
            <br />
            <br />
            <img
              :src="relatorio.assinaturas.AssinaturaConsultor"
              alt="Não foi assinado!!!"
              width="auto"
              height="300px"
              class="assinatura"
            />
            <br />
            <br />
            <span class="key-title">
              {{ relatorio.consultor.pessoafisica.Nome }}
            </span>
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ProximaVisita',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}

.assinatura {
  transform: rotate(90deg);
  margin-top: -40px;
  margin-bottom: -40px;
  border: solid 2px #333;
  border-radius: 10px;
}
</style>
