var render = function render(){var _vm=this,_c=_vm._self._c;return (
    ((_vm.relatorio.CriaRecria || _vm.relatorio.CriaRecriaObs) &&
      (_vm.relatorio.Step12Atividade1 ||
        _vm.relatorio.Step12Atividade2 ||
        _vm.relatorio.Step12Atividade3 ||
        _vm.relatorio.Step12Atividade4 ||
        _vm.relatorio.Step12ControleEspecifico ||
        _vm.relatorio.Step12ControleEspecificoText ||
        _vm.relatorio.Step12OutrasAtividades ||
        _vm.relatorio.Step12OutrasAtividadesText ||
        (_vm.relatorio.NovilhasComPeso != null &&
          _vm.relatorio.NovilhasComPeso.length) ||
        (_vm.relatorio.BezerrasDesmamar != null &&
          _vm.relatorio.BezerrasDesmamar.length) ||
        _vm.relatorio.PesagemRecria ||
        _vm.relatorio.OutrasRecomendacoesCriaRecria != '' ||
        (_vm.relatorio.ImagensAcompanhamentoCriaRecria != null &&
          _vm.relatorio.ImagensAcompanhamentoCriaRecria.length) ||
        _vm.relatorio.CriaRecriaObs)) ||
    (_vm.relatorio.criarecria &&
      (_vm.relatorio.criarecria.NovilhasComPeso ||
        _vm.relatorio.criarecria.BezerrasDesmamar ||
        _vm.relatorio.PesagemRecria ||
        _vm.relatorio.criarecria.Obs))
  )?_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Acompanhamento da cria/recria")]),_c('v-card-text',[(
        (_vm.relatorio.CriaRecria || _vm.relatorio.CriaRecriaObs) &&
        (_vm.relatorio.Step12Atividade1 ||
          _vm.relatorio.Step12Atividade2 ||
          _vm.relatorio.Step12Atividade3 ||
          _vm.relatorio.Step12Atividade4 ||
          _vm.relatorio.Step12ControleEspecifico ||
          _vm.relatorio.Step12ControleEspecificoText ||
          _vm.relatorio.Step12OutrasAtividades ||
          _vm.relatorio.Step12OutrasAtividadesText ||
          _vm.relatorio.NovilhasComPeso != null ||
          _vm.relatorio.BezerrasDesmamar != null ||
          _vm.relatorio.PesagemRecria ||
          _vm.relatorio.OutrasRecomendacoesCriaRecria != '' ||
          _vm.relatorio.CriaRecriaObs)
      )?_c('v-row',{staticClass:"pa-3"},[(
          _vm.relatorio.NovilhasComPeso != null &&
          _vm.relatorio.NovilhasComPeso.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Novilhas aptas à inseminação ")]),_vm._v(" "),_c('br'),_vm._l((_vm.relatorio.NovilhasComPeso),function(animal,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[_vm._v(" "+_vm._s(animal.acrNomeOuBrinco)+" ")])})],2):_vm._e(),(
          _vm.relatorio.BezerrasDesmamar != null &&
          _vm.relatorio.BezerrasDesmamar.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Bezerras a desmamar ")]),_vm._v(" "),_c('br'),_vm._l((_vm.relatorio.BezerrasDesmamar),function(animal,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[_vm._v(" "+_vm._s(animal.acrBezerrasDesmamarNomeOuBrinco)+" ")])})],2):_vm._e(),(
          _vm.relatorio.Step12Atividade1 ||
          _vm.relatorio.Step12Atividade2 ||
          _vm.relatorio.Step12Atividade3 ||
          _vm.relatorio.Step12Atividade4 ||
          _vm.relatorio.Step12ControleEspecifico ||
          _vm.relatorio.Step12ControleEspecificoText ||
          _vm.relatorio.Step12OutrasAtividades ||
          _vm.relatorio.Step12OutrasAtividadesText
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Atividades realizadas")]),(
            _vm.relatorio.Step12Atividade1 == '1' &&
            _vm.relatorio.Step12Atividade1Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Acompanhamento dos procedimentos de cria das bezerras (rotina do bezerreiro): ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step12Atividade1Text || '-'))])]):_vm._e(),(
            _vm.relatorio.Step12Atividade2 == '1' &&
            _vm.relatorio.Step12Atividade2Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Vistoria dos piquetes de recria: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step12Atividade2Text || '-'))])]):_vm._e(),(
            _vm.relatorio.Step12Atividade3 == '1' &&
            _vm.relatorio.Step12Atividade3Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Pesagem dos animais: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step12Atividade3Text || '-'))])]):_vm._e(),(
            _vm.relatorio.Step12Atividade4 == '1' &&
            _vm.relatorio.Step12Atividade4Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Discussão dos indicadores de cria e recria de bezerras: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step12Atividade4Text || '-'))])]):_vm._e(),(
            _vm.relatorio.Step12ControleEspecifico == '1' &&
            _vm.relatorio.Step12ControleEspecificoText != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Planos de ação para controle de doenças específicas de bezerras: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step12ControleEspecificoText || '-'))])]):_vm._e(),(
            _vm.relatorio.Step12OutrasAtividades != '' &&
            _vm.relatorio.Step12OutrasAtividades != '0'
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras Atividades: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step12OutrasAtividadesText || '-'))])]):_vm._e()],1):_vm._e(),(_vm.relatorio.PesagemRecria)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Recomendações:")]),(_vm.relatorio.PesagemRecria != '')?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Realizar pesagem mensal dos animais em recria para acompanhar ganho de peso ")])]):_vm._e(),(_vm.relatorio.OutrasRecomendacoesCriaRecria != '')?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras recomendações: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.OutrasRecomendacoesCriaRecria || '-'))])]):_vm._e()],1):_vm._e(),(_vm.relatorio.CriaRecriaObs)?_c('v-col',[_c('span',{staticClass:"key-title"},[_vm._v("Atividade não realizada ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.CriaRecriaObs || '-'))])]):_vm._e()],1):(
        (_vm.relatorio.criarecria &&
          (_vm.relatorio.criarecria.NovilhasComPeso ||
            _vm.relatorio.criarecria.BezerrasDesmamar ||
            _vm.relatorio.PesagemRecria ||
            _vm.relatorio.criarecria.Obs)) ||
        _vm.relatorio.CriaRecriaObs
      )?_c('v-row',{staticClass:"pa-3"},[(_vm.relatorio.criarecria.NovilhasComPeso)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Novilhas aptas à inseminação ")]),_vm._v(" "),_c('br'),_vm._l((_vm.maskIdentify(
            _vm.relatorio.criarecria.NovilhasComPeso,
          )),function(animal,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[_vm._v(" "+_vm._s(animal)+" ")])})],2):_vm._e(),(_vm.relatorio.criarecria.BezerrasDesmamar)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Bezerras a desmamar ")]),_vm._v(" "),_c('br'),_vm._l((_vm.maskIdentify(
            _vm.relatorio.criarecria.BezerrasDesmamar,
          )),function(animal,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[_vm._v(" "+_vm._s(animal)+" ")])})],2):_vm._e(),(_vm.relatorio.PesagemRecria)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Realizar pesagem mensal dos animais em recria para acompanhar ganho de peso ")])]):_vm._e(),(_vm.relatorio.criarecria.Obs)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras recomendações: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.criarecria.Obs || '-'))])]):_vm._e()],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }