var render = function render(){var _vm=this,_c=_vm._self._c;return (
    (_vm.relatorio.AjustesNutricionais &&
      (_vm.relatorio.CotacaoInsumoNutricional ||
        _vm.relatorio.PesagemLeiteApp ||
        _vm.relatorio.PesagemLeiteCaderno ||
        _vm.relatorio.RemanejamentoLotes ||
        _vm.relatorio.SeguirArracoamento ||
        _vm.relatorio.RecomendacaoNutricional ||
        _vm.relatorio.Step8Atividade1 ||
        _vm.relatorio.Step8Atividade2 ||
        _vm.relatorio.Step8Atividade3 ||
        _vm.relatorio.Step8Atividade4 ||
        _vm.relatorio.Step8Atividade5 ||
        _vm.relatorio.Step8Atividade6 ||
        _vm.relatorio.Step8Atividade7 ||
        _vm.relatorio.Step8OutrasAtividades ||
        _vm.relatorio.Step8OutrasAtividadesText)) ||
    _vm.relatorio.AjustesNutricionaisObs
  )?_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Ajustes nutricionais")]),_c('v-card-text',[(_vm.relatorio.AjustesNutricionais)?_c('v-row',{staticClass:"pa-3"},[(
          _vm.relatorio.Step8Atividade1 ||
          _vm.relatorio.Step8Atividade2 ||
          _vm.relatorio.Step8Atividade3 ||
          _vm.relatorio.Step8Atividade4 ||
          _vm.relatorio.Step8Atividade5 ||
          _vm.relatorio.Step8Atividade6 ||
          _vm.relatorio.Step8Atividade7 ||
          _vm.relatorio.Step8OutrasAtividades
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Atividades realizadas")]),(_vm.relatorio.Step8Atividade1)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Lançamento do controle leiteiro no sistema")])]):_vm._e(),(
            _vm.relatorio.Step8Atividade2 == '1' &&
            _vm.relatorio.Step8Atividade2Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Avaliação do escore de cocho: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step8Atividade2Text || '-'))])]):_vm._e(),(
            _vm.relatorio.Step8Atividade3 == '1' &&
            _vm.relatorio.Step8Atividade3Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Avaliação da estrutura de alimentação (espaço de cocho/animal, condições físicas das estruturas): ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step8Atividade3Text || '-'))])]):_vm._e(),(
            _vm.relatorio.Step8Atividade4 == '1' &&
            _vm.relatorio.Step8Atividade4Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Análise da dieta atual: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step8Atividade4Text || '-'))])]):_vm._e(),(
            _vm.relatorio.Step8Atividade5 == '1' &&
            _vm.relatorio.Step8Atividade5Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Divisão de lotes (Vacas em lactação): ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step8Atividade5Text || '-'))])]):_vm._e(),(
            _vm.relatorio.Step8Atividade6 == '1' &&
            _vm.relatorio.Step8Atividade6Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Divisão de lotes (Recria): ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step8Atividade6Text || '-'))])]):_vm._e(),(
            _vm.relatorio.Step8Atividade7 == '1' &&
            _vm.relatorio.Step8Atividade7Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Balanceamento da dieta: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step8Atividade7Text || '-'))])]):_vm._e(),(_vm.relatorio.Step8OutrasAtividades)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras Atividades: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step8OutrasAtividadesText || '-'))])]):_vm._e()],1):_vm._e(),(
          _vm.relatorio.CotacaoInsumoNutricional ||
          _vm.relatorio.PesagemLeiteApp ||
          _vm.relatorio.PesagemLeiteCaderno ||
          _vm.relatorio.RemanejamentoLotes ||
          _vm.relatorio.SeguirArracoamento ||
          _vm.relatorio.RecomendacaoNutricional
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Recomendações:")]),(_vm.relatorio.CotacaoInsumoNutricional)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Fazer cotação de insumos para custo da dieta ")])]):_vm._e(),(_vm.relatorio.PesagemLeiteApp)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Realizar a pesagem mensal de leite e lançar a pesagem no aplicativo ")])]):_vm._e(),(_vm.relatorio.PesagemLeiteCaderno)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Realizar a pesagem mensal de leite e lançar a pesagem no caderno zootécnico ")])]):_vm._e(),(_vm.relatorio.RemanejamentoLotes)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Realizar o remanejamento dos lotes conforme listagem em anexo ")])]):_vm._e(),(_vm.relatorio.SeguirArracoamento)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Seguir arraçoamento proposto em anexo ")])]):_vm._e(),(_vm.relatorio.RecomendacaoNutricional)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras recomendações: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.RecomendacaoNutricional || '-'))])]):_vm._e()],1):_vm._e()],1):_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('span',{staticClass:"key-title"},[_vm._v("Atividade não realizada ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.AjustesNutricionaisObs || '-'))])])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }