<template>
  <v-card
    v-if="
      (relatorio.Clinico &&
        ((relatorio.ListaAnimaisTratados != null &&
          relatorio.ListaAnimaisTratados.length != 0) ||
          (relatorio.Tratamento != null && relatorio.Tratamento.length != 0) ||
          relatorio.OutrasRecomendacoesAtendimentoClinico)) ||
      relatorio.ClinicoObs
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left">Atendimento clínico</v-card-title>
    <v-card-text>
      <v-row
        v-if="
          (relatorio.Clinico &&
            (relatorio.ListaAnimaisTratados ||
              relatorio.Tratamento ||
              relatorio.OutrasRecomendacoesAtendimentoClinico)) ||
          relatorio.ClinicoObs
        "
        class="pa-3"
      >
        <v-col
          v-if="
            relatorio.ListaAnimaisTratados != null &&
            relatorio.ListaAnimaisTratados.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Animais tratados </span> <br />
          <v-chip
            v-for="(animal, index) in relatorio.ListaAnimaisTratados"
            :key="index"
            class="ma-1"
          >
            <span v-if="relatorio.ListaAnimaisTratados">{{
              animal.acAnimaisTratadosNomeOuBrinco
            }}</span>
            <span v-else> {{ animal.acAnimaisTratadosNomeOuBrinco }} </span>
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.Tratamento != null && relatorio.Tratamento.length"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Tratamento </span> <br />
          <v-chip
            v-for="(medicamento, index) in relatorio.Tratamento"
            :key="index"
            class="ma-1"
          >
            <span v-if="relatorio.Tratamento"
              ><strong>Medicação: </strong
              >{{ medicamento.acTratamentoMedicacao + ' ' }}<br /><strong
                >Dosagem: </strong
              >{{ medicamento.acTratamentoDosagem + ' ' }}<br /><strong
                >Via de aplicação: </strong
              >{{ medicamento.acTratamentoViaAplicacao + ' ' }}<br /><strong
                >Duração do tratamento: </strong
              >{{ medicamento.acTratamentoDuracao + ' ' }}</span
            >
            <span v-else> {{ medicamento }} </span>
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.OutrasRecomendacoesAtendimentoClinico != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Outras recomendações: </span> <br />
          <span class="value-title">{{
            relatorio.OutrasRecomendacoesAtendimentoClinico || '-'
          }}</span>
        </v-col>
        <v-col v-if="relatorio.ClinicoObs">
          <span class="key-title">Atividade não realizada </span> <br />
          <span class="value-title">{{ relatorio.ClinicoObs || '-' }}</span>
        </v-col>
      </v-row>
      <v-row v-else-if="relatorio.Clinico" class="pa-3">
        <v-col
          v-if="relatorio.Clinico.AnimaisTratados"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Animais tratados </span> <br />
          <v-chip
            v-for="(animal, index) in maskIdentify(
              relatorio.Clinico.AnimaisTratados,
              'Nenhum animal indentificado',
            )"
            :key="index"
            class="ma-1"
          >
            <span v-if="relatorio.Clinico.AnimaisTratados">{{
              animal[0]
            }}</span>
            <span v-else> {{ animal }} </span>
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.Clinico.Tratamento"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Tratamento </span> <br />
          <v-chip
            v-for="(medicamento, index) in maskIdentify(
              relatorio.Clinico.Tratamento,
              'Nenhum medicamento identificado',
            )"
            :key="index"
            class="ma-1"
          >
            <span v-if="relatorio.Clinico.Tratamento"
              ><strong>Medicação: </strong>{{ medicamento[0] + ' '
              }}<br /><strong>Dosagem: </strong>{{ medicamento[1] + ' '
              }}<br /><strong>Via de aplicação: </strong
              >{{ medicamento[2] + ' ' }}<br /><strong
                >Duração do tratamento: </strong
              >{{ medicamento[3] + ' ' }}</span
            >
            <span v-else> {{ medicamento }} </span>
          </v-chip>
        </v-col>
        <v-col v-if="relatorio.Clinico.Obs" cols="12" class="col-bordered">
          <span class="key-title">Outras recomendações: </span> <br />
          <span class="value-title">{{ relatorio.Clinico.Obs || '-' }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ENV_APP } from '../../../../../env'
export default {
  name: 'Clinico',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    maskIdentify(raw, noneText) {
      if (raw) {
        raw = raw.split('|')
        for (const index in raw) {
          raw[index] = raw[index].split('/')
        }
      } else {
        raw = [noneText]
      }
      return raw
    },
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
  line-height: 40px;
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
