import _ from 'underscore'
import { monthShortLabel } from './dashboard'
import { colors } from './theme'

export function proteinForIntervalCCS(data) {
  if (!data) return

  const dataGraph = {
    labels: [...monthShortLabel],
    datasets: [
      {
        label: '% CCS < 500',
        backgroundColor: colors[1],
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        label: '% CCS 500 - 1000',
        backgroundColor: colors[0],
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        label: '% CCS > 1000',
        backgroundColor: colors[2],
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  }

  let result = _.groupBy(data, item => item.month)

  Object.keys(result).forEach(key => {
    let total = result[key].length
    let ccs500 = result[key].filter(item => Number(item.ccsMedia) < 500).length
    let ccs1000 = result[key].filter(
      item => Number(item.ccsMedia) >= 500 && Number(item.ccsMedia) <= 1000,
    ).length
    let ccsMore1000 = result[key].filter(item => Number(item.ccsMedia) > 1000)
      .length

    dataGraph.datasets[0].data[key - 1] = (ccs500 / total) * 100
    dataGraph.datasets[1].data[key - 1] = (ccs1000 / total) * 100
    dataGraph.datasets[2].data[key - 1] = (ccsMore1000 / total) * 100
  })

  return dataGraph
}

export function proteinForIntervalCPP(data) {
  if (!data) return

  const dataGraph = {
    labels: [...monthShortLabel],
    datasets: [
      {
        label: '% CPP < 50',
        backgroundColor: colors[1],
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        label: '% CPP 50 - 300',
        backgroundColor: colors[0],
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        label: '% CPP > 300',
        backgroundColor: colors[2],
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  }

  let result = _.groupBy(data, item => item.month)

  Object.keys(result).forEach(key => {
    let total = result[key].length
    let cpp50 = result[key].filter(item => Number(item.cppMedia) < 50).length
    let cpp300 = result[key].filter(
      item => Number(item.cppMedia) >= 50 && Number(item.cppMedia) <= 300,
    ).length
    let cppMore300 = result[key].filter(item => Number(item.cppMedia) > 300)
      .length

    dataGraph.datasets[0].data[key - 1] = (cpp50 / total) * 100
    dataGraph.datasets[1].data[key - 1] = (cpp300 / total) * 100
    dataGraph.datasets[2].data[key - 1] = (cppMore300 / total) * 100
  })

  return dataGraph
}

export function avgProteinAndFat(proteinAvg, fatAvg) {
  if (!proteinAvg && !fatAvg) return

  const dataGraph = {
    labels: [...monthShortLabel],
    datasets: [
      {
        label: 'Proteína',
        backgroundColor: colors[2],
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: colors[2],
        tension: 0.1,
      },
      {
        label: 'Gordura',
        backgroundColor: colors[1],
        borderColor: colors[1],
        tension: 0.1,
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  }

  let resultProtein = _.groupBy(proteinAvg, item => item.month)
  let resultFat = _.groupBy(fatAvg, item => item.month)

  Object.keys(resultProtein).forEach(key => {
    dataGraph.datasets[0].data[key - 1] = resultProtein[key][0].avg
  })

  Object.keys(resultFat).forEach(key => {
    dataGraph.datasets[1].data[key - 1] = resultFat[key][0].avg
  })

  return dataGraph
}

export function weightedAverageCCS(data, attrubuteName) {
  if (!data) return

  let group = _.groupBy(data, item => item.year)

  group = Object.keys(group).map(key => {
    group[key] = _.groupBy(group[key], item => item.month)
    return group
  })

  const result = {}

  group.forEach(obj => {
    Object.entries(obj).forEach(([key, value]) => {
      const r = {}
      Object.entries(value).forEach(([key, value]) => {
        const length = value.length

        const avg =
          value.reduce((acc, item) => acc + Number(item[attrubuteName]), 0) /
          length

        r[key] = { avg }
      })

      result[key] = r
    })
  })

  let index = 2
  const dataGraph = []

  Object.entries(result).forEach(([key, value]) => {
    let tmp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

    Object.entries(value).forEach(([key, value]) => {
      tmp[key - 1] = value.avg
    })

    dataGraph.push({
      data: tmp,
      label: key,
      backgroundColor: colors[index],
    })

    index--

    tmp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  })

  return dataGraph
}
