import HtmlToCanvas from 'html2canvas'
import JsPDF from 'jspdf'
import moment from 'moment'
import { ENV_APP } from '../../../env'
import { convertNumberBR } from '../../utils/masks'

const utilsRelatorio = {
  getDataAtual() {
    var monName = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ]
    var now = new Date()

    var str =
      'Impresso em ' +
      now.getDate() +
      ' de ' +
      monName[now.getMonth()] +
      ' de ' +
      now.getFullYear() +
      ' às ' +
      formatDate(now, 'HH:mm:ss')
    return str
  },
  calcLacVacas(relatorio) {
    if (relatorio.VacasLactacao && relatorio.VacasSecas) {
      return `${convertNumberBR(
        100 *
          (parseInt(relatorio.VacasLactacao) /
            (parseInt(relatorio.VacasLactacao) +
              parseInt(relatorio.VacasSecas))),
      )} %`
    } else {
      return '-'
    }
  },
  calcLacRebanho(relatorio) {
    var totalRebanho = 0
    if (relatorio.VacasLactacao) {
      totalRebanho += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalRebanho += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.Novilhas) {
      totalRebanho += parseInt(relatorio.Novilhas)
    }
    if (relatorio.Recria) {
      totalRebanho += parseInt(relatorio.Recria)
    }
    if (relatorio.Aleitamento) {
      totalRebanho += parseInt(relatorio.Aleitamento)
    }
    if (relatorio.Machos) {
      totalRebanho += parseInt(relatorio.Machos)
    }
    if (totalRebanho > 0) {
      return `${convertNumberBR(
        100 * (parseInt(relatorio.VacasLactacao) / totalRebanho),
      )} %`
    } else {
      return '-'
    }
  },
  calcProdutividade(relatorio) {
    if (relatorio.VacasLactacao && relatorio.ProducaoMedia) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / parseInt(relatorio.VacasLactacao),
      )} L / dia`
    } else {
      return '-'
    }
  },
  calcProdutividadeTotal(relatorio) {
    var totalVacas = 0
    if (relatorio.VacasLactacao) {
      totalVacas += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalVacas += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.ProducaoMedia && totalVacas > 0) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / totalVacas,
      )} L / dia`
    } else {
      return '-'
    }
  },
  calcProdutividadeDH(relatorio) {
    if (relatorio.NumeroMDO && relatorio.ProducaoMedia) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / parseInt(relatorio.NumeroMDO),
      )}`
    } else {
      return '-'
    }
  },
}

function formatDate(date, format = 'DD/MM/YYYY') {
  return moment(date).format(format)
}

function renderDiscussao(doc, data, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config
  data = data || '-'
  const width = doc.getTextWidth(data)
  const numberLines = parseInt(width / (tableLimit - smallGutterX * 2) + 1)

  cursor = checkAddPage(doc, cursor, smallBoxHeight * numberLines, config)

  doc.rect(startX, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.text(data, startX + smallGutterX, cursor + smallGutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.rect(startX, cursor + smallBoxHeight * numberLines, tableLimit, line, 'F')

  return cursor + smallBoxHeight * numberLines
}

function renderIdentificacaoSanitario(
  doc,
  text,
  noneText,
  data,
  cursor,
  config,
) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    gutterY,
    startX,
    tableLimit,
  } = config

  if (!data) return cursor

  if (data) {
    data = data.split('|')
    for (const index in data) {
      data[index] = data[index].replace(/\//g, ' | ')
    }
  } else {
    data = [noneText]
  }

  const numberLines = data.length + 1

  cursor = checkAddPage(doc, cursor, smallBoxHeight * numberLines, config)

  doc.rect(startX, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY)

  for (const index in data) {
    doc.text(
      data[index],
      startX + smallGutterX,
      cursor + gutterY + smallBoxHeight * index,
    )
  }

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.rect(startX, cursor + smallBoxHeight * numberLines, tableLimit, line, 'F')

  return cursor + smallBoxHeight * numberLines
}

function renderIdentificacao(doc, text, noneText, data, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    gutterY,
    startX,
    tableLimit,
  } = config

  if (!data) return cursor

  data = data ? data.replace(/\|/g, ', ') : noneText
  const width = doc.getTextWidth(data)
  const numberLines = parseInt(width / (tableLimit - smallGutterX * 2) + 2)

  cursor = checkAddPage(doc, cursor, smallBoxHeight * numberLines, config)

  doc.rect(startX, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY)

  doc.text(data, startX + smallGutterX, cursor + gutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.rect(startX, cursor + smallBoxHeight * numberLines, tableLimit, line, 'F')

  return cursor + smallBoxHeight * numberLines
}

function renderCheckbox(doc, text, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config

  doc.rect(startX, cursor, line, smallBoxHeight, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY)

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')

  doc.rect(startX, cursor + smallBoxHeight, tableLimit, line, 'F')
}

function renderRecomendacoes(doc, text, data, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    gutterY,
    startX,
    tableLimit,
  } = config

  if (!data) return cursor

  data = data || '-'
  const width = doc.getTextWidth(data)
  const numberLines = parseInt(width / (tableLimit - smallGutterX * 2) + 2)

  cursor = checkAddPage(doc, cursor, smallBoxHeight * numberLines, config)

  doc.rect(startX, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.setFont('helvetica', 'normal')
  doc.text(text, startX + smallGutterX, cursor + smallGutterY)

  doc.text(
    data.replace(/[\r\n]+/gm, ''),
    startX + smallGutterX,
    cursor + gutterY,
    {
      maxWidth: tableLimit - smallGutterX * 2,
    },
  )

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.rect(startX, cursor + smallBoxHeight * numberLines, tableLimit, line, 'F')

  return cursor + smallBoxHeight * numberLines
}

function checkAddPage(doc, cursor, area, config) {
  const { heightLimit, margin, tableLimit, line, startX } = config

  if (cursor + area > heightLimit) {
    doc.addPage()
    cursor = margin
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  return cursor
}

function header(doc, config, relatorio) {
  const logo1 = new Image()
  const logo2 = new Image()

  logo1.src = config.logo

  doc.addImage(logo1, 'PNG', config.margin, config.margin, 70, 55)
  doc.setFontSize(config.largefontSize).setFont('helvetica', 'bold')

  const widthOfString = doc.getTextWidth('Relatório de visita geral')

  doc.text(
    'Relatório de visita geral',
    config.tableLimit / 2 - widthOfString / 2 + config.margin,
    config.margin + 30,
  )

  if (relatorio.logoagroindustria) {
    logo2.src = relatorio.logoagroindustria
    doc.addImage(logo2, 'PNG', config.tableLimit - 50, config.margin, 73, 50)
  }

  doc.setFontSize(config.fontSize).setFont('helvetica', 'normal')
}

function subHeader(doc, _ref, _ref2) {
  const {
    fazenda: {
      produtor: { Nome: produtor },
      pessoajuridica: { NomeFantasia: fazenda },
      pessoa: { endereco },
    },
    consultor: {
      pessoafisica: { Nome: consultor },
    },
    DataInicio,
    DataFim,
    NumeroVisita,
  } = _ref
  const {
    boxHeight,
    gutterY,
    line,
    lineColor,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = _ref2

  doc.setFillColor(lineColor)

  doc.rect(startX, startY, tableLimit, line, 'F')

  doc.rect(startX, startY, line, boxHeight, 'F')

  doc.text('Fazenda:', startX + smallGutterX, startY + smallGutterY)

  doc
    .setFont('helvetica', 'bold')
    .text(fazenda, startX + smallGutterX, startY + gutterY)
    .setFont('helvetica', 'normal')

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.rect(startX + tableLimit, startY, line, boxHeight, 'F')

  doc.text(
    'Produtor(a):',
    startX + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    produtor.substring(0, 25),
    startX + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 3, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Consultor(a):',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    consultor,
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Cidade:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    endereco ? endereco.cidade.Nome : '-',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.text(
    'Data:',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    formatDate(DataInicio, 'DD/MM/YYYY'),
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 4,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do início:',
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    formatDate(DataInicio, 'HH:mm'),
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do término:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    formatDate(DataFim, 'HH:mm'),
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 4,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Número da visita:',
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${NumeroVisita}ª visita`,
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')
}

function indicadoresQualidade(doc, relatorio, _ref2) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = _ref2

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Principais Indicadores', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produção média do último mês:',
    startX + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.ProducaoMedia
      ? `${relatorio.ProducaoMedia} L / Dia`
      : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 3, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'CPP média do último mês:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.CppMedia ? `${relatorio.CppMedia} UFC/ml * 10³` : 'Não informado',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'CCS média do último mês:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.CcsMedia
      ? `${relatorio.CcsMedia} céls/ml * 10³`
      : 'Não informado',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.text(
    'Número de pessoas envolvidas no manejo de rebanho:',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.NumeroMDO ? `${relatorio.NumeroMDO}` : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Área para produção de leite:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.AreaProducao ? `${relatorio.AreaProducao} ha` : 'Não informado',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Produtividade/DH:',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcProdutividadeDH(relatorio)}`,
    startX + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Produtividade / Área:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    relatorio.ProducaoMedia && relatorio.AreaProducao
      ? `${convertNumberBR(
          (parseInt(relatorio.ProducaoMedia) * 365) /
            parseInt(relatorio.AreaProducao),
        )} L / ha / ano`
      : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')
}

function composicaoRebanho(doc, relatorio, _ref2) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = _ref2

  const cursor = startY + boxHeight + 170

  if (
    relatorio.VacasLactacao != null ||
    relatorio.VacasSecas != null ||
    relatorio.Novilhas != null ||
    relatorio.Machos != null ||
    relatorio.Recria != null ||
    relatorio.Aleitamento != null
  ) {
    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Composição do rebanho', startX, startY + 10)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    doc.rect(startX, cursor + boxHeight, tableLimit, line, 'F')

    doc.rect(startX, cursor + boxHeight, line, boxHeight, 'F')

    doc.text(
      'Vacas em lactação:',
      startX + smallGutterX,
      cursor + boxHeight + smallGutterY,
    )

    doc.text(
      `${relatorio.VacasLactacao}`,
      startX + smallGutterX,
      cursor + boxHeight + gutterY,
    )

    doc.rect(startX + tableLimit / 3, cursor + boxHeight, line, boxHeight, 'F')

    doc.text(
      'Vacas secas:',
      startX + tableLimit / 3 + smallGutterX,
      cursor + boxHeight + smallGutterY,
    )

    doc.text(
      `${relatorio.VacasSecas}`,
      startX + tableLimit / 3 + smallGutterX,
      cursor + boxHeight + gutterY,
    )

    doc.rect(
      startX + tableLimit - tableLimit / 3,
      cursor + boxHeight,
      line,
      boxHeight,
      'F',
    )

    doc.text(
      'Novilhas:',
      startX + tableLimit - tableLimit / 3 + smallGutterX,
      cursor + boxHeight + smallGutterY,
    )

    doc.text(
      `${relatorio.Novilhas}`,
      startX + tableLimit - tableLimit / 3 + smallGutterX,
      cursor + boxHeight + gutterY,
    )

    doc.rect(startX + tableLimit, cursor + boxHeight, line, boxHeight, 'F')

    doc.rect(startX, cursor + boxHeight * 2, tableLimit, line, 'F')

    doc.rect(startX, cursor + boxHeight * 2, line, boxHeight, 'F')

    doc.text(
      'Machos:',
      startX + smallGutterX,
      cursor + boxHeight * 2 + smallGutterY,
    )

    doc.text(
      `${relatorio.Machos}`,
      startX + smallGutterX,
      cursor + boxHeight * 2 + gutterY,
    )

    doc.rect(
      startX + tableLimit / 3,
      cursor + boxHeight * 2,
      line,
      boxHeight,
      'F',
    )

    doc.text(
      'Animais em recria:',
      startX + tableLimit / 3 + smallGutterX,
      cursor + boxHeight * 2 + smallGutterY,
    )

    doc.text(
      `${relatorio.Recria}`,
      startX + tableLimit / 3 + smallGutterX,
      cursor + boxHeight * 2 + gutterY,
    )

    doc.rect(
      startX + tableLimit - tableLimit / 3,
      cursor + boxHeight * 2,
      line,
      boxHeight,
      'F',
    )

    doc.text(
      'Animais em aleitamento::',
      startX + tableLimit - tableLimit / 3 + smallGutterX,
      cursor + boxHeight * 2 + smallGutterY,
    )

    doc.text(
      `${relatorio.Aleitamento}`,
      startX + tableLimit - tableLimit / 3 + smallGutterX,
      cursor + boxHeight * 2 + gutterY,
    )

    doc.rect(startX + tableLimit, cursor + boxHeight * 2, line, boxHeight, 'F')

    doc.rect(startX, cursor + boxHeight * 3, tableLimit, line, 'F')

    doc.rect(startX, cursor + boxHeight * 3, line, boxHeight, 'F')

    doc.text(
      'Vacas em lactação / total de vacas:',
      startX + smallGutterX,
      cursor + boxHeight * 3 + smallGutterY,
    )

    doc.text(
      `${utilsRelatorio.calcLacVacas(relatorio)}`,
      startX + smallGutterX,
      cursor + boxHeight * 3 + gutterY,
    )

    doc.rect(
      startX + tableLimit / 2,
      cursor + boxHeight * 3,
      line,
      boxHeight,
      'F',
    )

    doc.text(
      'Vacas em lactação / total do rebanho:',
      startX + tableLimit / 2 + smallGutterX,
      cursor + boxHeight * 3 + smallGutterY,
    )

    doc.text(
      `${utilsRelatorio.calcLacRebanho(relatorio)}`,
      startX + tableLimit / 2 + smallGutterX,
      cursor + boxHeight * 3 + gutterY,
    )

    doc.rect(startX + tableLimit, cursor + boxHeight * 3, line, boxHeight, 'F')

    doc.rect(startX, cursor + boxHeight * 4, tableLimit, line, 'F')

    doc.rect(startX, cursor + boxHeight * 4, line, boxHeight, 'F')

    doc.text(
      'Produtividade / vacas em lactação:',
      startX + smallGutterX,
      cursor + boxHeight * 4 + smallGutterY,
    )

    doc.text(
      `${utilsRelatorio.calcProdutividade(relatorio)}`,
      startX + smallGutterX,
      cursor + boxHeight * 4 + gutterY,
    )

    doc.rect(
      startX + tableLimit / 2,
      cursor + boxHeight * 4,
      line,
      boxHeight,
      'F',
    )

    doc.text(
      'Produtividade / total de vacas:',
      startX + tableLimit / 2 + smallGutterX,
      cursor + boxHeight * 4 + smallGutterY,
    )

    doc.text(
      `${utilsRelatorio.calcProdutividadeTotal(relatorio)}`,
      startX + tableLimit / 2 + smallGutterX,
      cursor + boxHeight * 4 + gutterY,
    )

    doc.rect(startX + tableLimit, cursor + boxHeight * 4, line, boxHeight, 'F')

    doc.rect(startX, cursor + boxHeight * 5, tableLimit, line, 'F')
  }
  return cursor + boxHeight * 5
}

function coletaZootecnico(doc, relatorio, _ref2, cursor) {
  const {
    smallBoxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    startY,
    tableLimit,
  } = _ref2

  if (
    relatorio.ManterAttZootecnico ||
    relatorio.ManterAppSync ||
    relatorio.ColocarBrincoRecria ||
    relatorio.ColocarBrincoLactacao ||
    relatorio.ColocarBrincoSecas ||
    relatorio.RecomendacaoZootecnico ||
    relatorio.ColetaZootecnicoObs
  ) {
    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Coleta e atualização de dados zootécnicos', startX, startY + 5)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    doc.rect(startX, startY + smallBoxHeight, tableLimit, line, 'F')

    cursor = startY + smallBoxHeight

    if (relatorio.ColetaZootecnico) {
      if (relatorio.ManterAttZootecnico) {
        renderCheckbox(
          doc,
          'Manter atualizações dos lançamentos zootécnicos em dia',
          cursor,
          _ref2,
        )
        cursor += smallBoxHeight
      }
      if (relatorio.ManterAppSync) {
        renderCheckbox(
          doc,
          'Manter o aplicativo atualizado semanalmente',
          cursor,
          _ref2,
        )
        cursor += smallBoxHeight
      }
      if (relatorio.ColocarBrincoRecria) {
        renderCheckbox(
          doc,
          'Colocar brinco de identificação nos animais em recria',
          cursor,
          _ref2,
        )
        cursor += smallBoxHeight
      }
      if (relatorio.ColocarBrincoLactacao) {
        renderCheckbox(
          doc,
          'Colocar brinco de identificação nas vacas em lactação',
          cursor,
          _ref2,
        )
        cursor += smallBoxHeight
      }
      if (relatorio.ColocarBrincoSecas) {
        renderCheckbox(
          doc,
          'Colocar brinco de identificação nas vacas secas',
          cursor,
          _ref2,
        )
        cursor += smallBoxHeight
      }
      if (relatorio.RecomendacaoZootecnico) {
        cursor = renderRecomendacoes(
          doc,
          'Outras recomendações:',
          relatorio.RecomendacaoZootecnico,
          cursor,
          _ref2,
        )
      }
    } else {
      cursor = renderRecomendacoes(
        doc,
        'Atividade não realizada',
        relatorio.ColetaZootecnicoObs,
        cursor,
        _ref2,
      )
    }
  }

  return cursor
}

function coletaFinanceiro(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    (relatorio.ColetaFinanceiro &&
      (relatorio.ManterAttFinanceiro ||
        relatorio.FinanciamentoAVenver ||
        relatorio.ImplementosAVencer ||
        relatorio.EmprestimosAVencer ||
        relatorio.ArmazenarNotasDespesas ||
        relatorio.RecomendacaoFinanceiro)) ||
    relatorio.ColetaFinanceiroObs
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Coleta e atualização de dados financeiros', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    if (relatorio.ColetaFinanceiro) {
      if (relatorio.ManterAttFinanceiro) {
        renderCheckbox(
          doc,
          'Manter atualizações dos lançamentos financeiros em dia',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.FinanciamentoAVenver) {
        renderCheckbox(
          doc,
          'Fazer o levantamento dos financiamentos a vencer para serem lançados no aplicativo',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.ImplementosAVencer) {
        renderCheckbox(
          doc,
          'Fazer o levantamento das parcelas dos implementos a vencer para serem lançados no aplicativo',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.EmprestimosAVencer) {
        renderCheckbox(
          doc,
          'Fazer o levantamento das parcelas dos empréstimos a vencer para serem lançados no aplicativo',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.ArmazenarNotasDespesas) {
        renderCheckbox(
          doc,
          'Armazenar todas as notas de despesas até que sejam lançadas no aplicativo',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      cursor = renderRecomendacoes(
        doc,
        'Outras recomendações:',
        relatorio.RecomendacaoFinanceiro,
        cursor,
        config,
      )
    } else {
      cursor = renderRecomendacoes(
        doc,
        'Atividade não realizada',
        relatorio.ColetaFinanceiroObs,
        cursor,
        config,
      )
    }
  }

  return cursor
}

function manejoReprodutivo(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
    smallGutterX,
    smallGutterY,
    gutterY,
  } = config

  if (
    (relatorio.vacas &&
      (relatorio.vacas.Vacas ||
        relatorio.vacas.VacasGestantes ||
        relatorio.vacas.VacasReavaliar ||
        relatorio.vacas.VacasVazias ||
        relatorio.vacas.IdentificacaoGestantes ||
        relatorio.vacas.IdentificacaoReavaliar ||
        relatorio.vacas.IdentificacaoIatf ||
        relatorio.vacas.IdentificacaoPgf ||
        relatorio.vacas.IdentificacaoEscore ||
        relatorio.vacas.IdentificacaoClinico ||
        relatorio.vacas.SepararTouros ||
        relatorio.vacas.Obs)) ||
    (relatorio.novilhas !== null &&
      (relatorio.novilhas.Novilhas ||
        relatorio.novilhas.NovilhasGestantes ||
        relatorio.novilhas.NovilhasReavaliar ||
        relatorio.novilhas.NovilhasVazias ||
        relatorio.novilhas.IdentificacaoGestantes ||
        relatorio.novilhas.IdentificacaoReavaliar ||
        relatorio.novilhas.IdentificacaoIatf ||
        relatorio.novilhas.IdentificacaoPgf ||
        relatorio.novilhas.IdentificacaoEscore ||
        relatorio.novilhas.IdentificacaoClinico ||
        relatorio.novilhas.SepararTouros ||
        relatorio.novilhas.Obs)) ||
    relatorio.ReprodutivoObs
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 3 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Manejo reprodutivo', startX, cursor)
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    if (relatorio.Reprodutivo && relatorio.vacas) {
      doc
        .text('Reprodutivo de Vacas', startX, cursor)
        .setFont('helvetica', 'normal')

      cursor += smallBoxHeight - 5

      doc.rect(startX, cursor, tableLimit, line, 'F')

      doc.rect(startX, cursor, line, boxHeight, 'F')

      doc.text('Vacas avaliadas:', startX + smallGutterX, cursor + smallGutterY)

      doc.text(
        `${relatorio.vacas.Vacas || '-'}`,
        startX + smallGutterX,
        cursor + gutterY,
      )

      doc.rect(startX + tableLimit / 4, cursor, line, boxHeight, 'F')

      doc.text(
        'Vacas gestantes:',
        startX + tableLimit / 4 + smallGutterX,
        cursor + smallGutterY,
      )

      doc.text(
        `${relatorio.vacas.VacasGestantes || '-'}`,
        startX + tableLimit / 4 + smallGutterX,
        cursor + gutterY,
      )

      doc.rect(startX + tableLimit / 2, cursor, line, boxHeight, 'F')

      doc.text(
        'Vacas a reavaliar:',
        startX + tableLimit / 2 + smallGutterX,
        cursor + smallGutterY,
      )

      doc.text(
        `${relatorio.vacas.VacasReavaliar || '-'}`,
        startX + tableLimit / 2 + smallGutterX,
        cursor + gutterY,
      )

      doc.rect(
        startX + tableLimit - tableLimit / 4,
        cursor,
        line,
        boxHeight,
        'F',
      )

      doc.text(
        'Vacas vazias:',
        startX + tableLimit - tableLimit / 4 + smallGutterX,
        cursor + smallGutterY,
      )

      doc.text(
        `${relatorio.vacas.VacasVazias || '-'}`,
        startX + tableLimit - tableLimit / 4 + smallGutterX,
        cursor + gutterY,
      )

      doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F')

      cursor += boxHeight

      doc.rect(startX, cursor, tableLimit, line, 'F')

      if (
        relatorio.vacas.Vacas ||
        relatorio.vacas.VacasGestantes ||
        relatorio.vacas.VacasReavaliar ||
        relatorio.vacas.VacasVazias ||
        relatorio.vacas.IdentificacaoGestantes ||
        relatorio.vacas.IdentificacaoReavaliar ||
        relatorio.vacas.IdentificacaoIatf ||
        relatorio.vacas.IdentificacaoPgf ||
        relatorio.vacas.IdentificacaoEscore ||
        relatorio.vacas.IdentificacaoClinico ||
        relatorio.vacas.SepararTouros ||
        relatorio.vacas.Obs
      ) {
        cursor = renderIdentificacao(
          doc,
          'Identificação das vacas gestantes:',
          'Nenhum animal identificado',
          relatorio.vacas.IdentificacaoGestantes,
          cursor,
          config,
        )

        cursor = renderIdentificacao(
          doc,
          'Identificação das vacas para reavaliar:',
          'Nenhum animal identificado',
          relatorio.vacas.IdentificacaoReavaliar,
          cursor,
          config,
        )

        cursor = renderIdentificacao(
          doc,
          'Identificação das vacas vazias que entraram no protocolo de IATF:',
          'Nenhum animal identificado',
          relatorio.vacas.IdentificacaoIatf,
          cursor,
          config,
        )

        cursor = renderIdentificacao(
          doc,
          'Identificação das vacas vazias que foram sincronizadas no PGF:',
          'Nenhum animal identificado',
          relatorio.vacas.IdentificacaoPgf,
          cursor,
          config,
        )

        cursor = renderIdentificacao(
          doc,
          'Identificação das vacas vazias que precisam Recuperar o Escore de Condição Corporal(ECC):',
          'Nenhum animal identificado',
          relatorio.vacas.IdentificacaoEscore,
          cursor,
          config,
        )

        cursor = renderIdentificacao(
          doc,
          'Identificação das vacas vazias que precisam de tratamento clínico:',
          'Nenhum animal identificado',
          relatorio.vacas.IdentificacaoClinico,
          cursor,
          config,
        )

        if (relatorio.vacas.SepararTouros) {
          renderCheckbox(doc, 'Separar o touro das vacas', cursor, config)
          cursor += smallBoxHeight
        }

        cursor = renderRecomendacoes(
          doc,
          'Outras recomendações:',
          relatorio.vacas.Obs,
          cursor,
          config,
        )

        cursor += smallBoxHeight

        if (
          relatorio.novilhas.Novilhas != null ||
          relatorio.novilhas.NovilhasGestantes != null ||
          relatorio.novilhas.NovilhasReavaliar != null ||
          relatorio.novilhas.NovilhasVazias != null ||
          relatorio.novilhas.IdentificacaoGestantes != null ||
          relatorio.novilhas.IdentificacaoReavaliar != null ||
          relatorio.novilhas.IdentificacaoIatf != null ||
          relatorio.novilhas.IdentificacaoPgf != null ||
          relatorio.novilhas.IdentificacaoEscore != null ||
          relatorio.novilhas.IdentificacaoClinico != null ||
          relatorio.novilhas.Obs
        ) {
          doc
            .setFont('helvetica', 'bold')
            .text('Reprodutivo de Recrias', startX, cursor)
            .setFont('helvetica', 'normal')

          cursor += smallBoxHeight - 5

          doc.rect(startX, cursor, tableLimit, line, 'F')

          doc.rect(startX, cursor, line, boxHeight, 'F')

          doc.text(
            'Novilhas avaliadas:',
            startX + smallGutterX,
            cursor + smallGutterY,
          )

          doc.text(
            `${relatorio.novilhas.Novilhas || '-'}`,
            startX + smallGutterX,
            cursor + gutterY,
          )

          doc.rect(startX + tableLimit / 4, cursor, line, boxHeight, 'F')

          doc.text(
            'Novilhas gestantes:',
            startX + tableLimit / 4 + smallGutterX,
            cursor + smallGutterY,
          )

          doc.text(
            `${relatorio.novilhas.NovilhasGestantes || '-'}`,
            startX + tableLimit / 4 + smallGutterX,
            cursor + gutterY,
          )

          doc.rect(startX + tableLimit / 2, cursor, line, boxHeight, 'F')

          doc.text(
            'Novilhas a reavaliar:',
            startX + tableLimit / 2 + smallGutterX,
            cursor + smallGutterY,
          )

          doc.text(
            `${relatorio.novilhas.NovilhasReavaliar || '-'}`,
            startX + tableLimit / 2 + smallGutterX,
            cursor + gutterY,
          )

          doc.rect(
            startX + tableLimit - tableLimit / 4,
            cursor,
            line,
            boxHeight,
            'F',
          )

          doc.text(
            'Novilhas vazias:',
            startX + tableLimit - tableLimit / 4 + smallGutterX,
            cursor + smallGutterY,
          )

          doc.text(
            `${relatorio.novilhas.NovilhasVazias || '-'}`,
            startX + tableLimit - tableLimit / 4 + smallGutterX,
            cursor + gutterY,
          )

          doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F')

          cursor += boxHeight

          doc.rect(startX, cursor, tableLimit, line, 'F')

          cursor = renderIdentificacao(
            doc,
            'Identificação das recrias gestantes:',
            'Nenhum animal identificado',
            relatorio.novilhas.IdentificacaoGestantes,
            cursor,
            config,
          )

          cursor = renderIdentificacao(
            doc,
            'Identificação das recrias para reavaliar:',
            'Nenhum animal identificado',
            relatorio.novilhas.IdentificacaoReavaliar,
            cursor,
            config,
          )

          cursor = renderIdentificacao(
            doc,
            'Identificação das recrias vazias que entraram no protocolo de IATF:',
            'Nenhum animal identificado',
            relatorio.novilhas.IdentificacaoIatf,
            cursor,
            config,
          )

          cursor = renderIdentificacao(
            doc,
            'Identificação das recrias vazias que foram sincronizadas no PGF:',
            'Nenhum animal identificado',
            relatorio.novilhas.IdentificacaoPgf,
            cursor,
            config,
          )

          cursor = renderIdentificacao(
            doc,
            'Identificação das recrias vazias que precisam Recuperar o Escore de Condição Corporal(ECC):',
            'Nenhum animal identificado',
            relatorio.novilhas.IdentificacaoEscore,
            cursor,
            config,
          )

          cursor = renderIdentificacao(
            doc,
            'Identificação das recrias vazias que precisam de tratamento clínico:',
            'Nenhum animal identificado',
            relatorio.novilhas.IdentificacaoClinico,
            cursor,
            config,
          )

          if (relatorio.novilhas.SepararTouros) {
            renderCheckbox(doc, 'Separar o touro das recrias', cursor, config)
            cursor += smallBoxHeight
          }

          cursor = renderRecomendacoes(
            doc,
            'Outras recomendações:',
            relatorio.novilhas.Obs,
            cursor,
            config,
          )
        }
      }
    } else {
      doc.rect(startX, cursor, tableLimit, line, 'F')
      cursor = renderRecomendacoes(
        doc,
        'Atividade não realizada',
        relatorio.ReprodutivoObs,
        cursor,
        config,
      )
    }
  }

  return cursor
}

function ajustesNutricionais(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    (relatorio.AjustesNutricionais &&
      (relatorio.CotacaoInsumoNutricional ||
        relatorio.PesagemLeiteApp ||
        relatorio.PesagemLeiteCaderno ||
        relatorio.RemanejamentoLotes ||
        relatorio.SeguirArracoamento ||
        relatorio.RecomendacaoNutricional)) ||
    relatorio.AjustesNutricionaisObs
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Ajustes nutricionais', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    if (relatorio.AjustesNutricionais) {
      if (relatorio.CotacaoInsumoNutricional) {
        cursor = renderIdentificacao(
          doc,
          'Identificação de insumos cotados para custo da dieta:',
          'Nenhum insumo identificado',
          relatorio.ListaInsumosNutricionais,
          cursor,
          config,
        )
      }
      if (relatorio.PesagemLeiteApp) {
        renderCheckbox(
          doc,
          'Realizar a pesagem mensal de leite e lançar a pesagem no aplicativo',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.PesagemLeiteCaderno) {
        renderCheckbox(
          doc,
          'Realizar a pesagem mensal de leite e lançar a pesagem no caderno zootécnico',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.RemanejamentoLotes) {
        renderCheckbox(
          doc,
          'Realizar o remanejamento dos lotes conforme listagem em anexo',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.SeguirArracoamento) {
        renderCheckbox(
          doc,
          'Seguir arraçoamento proposto em anexo',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.RecomendacaoNutricional) {
        cursor = renderRecomendacoes(
          doc,
          'Outras recomendações:',
          relatorio.RecomendacaoNutricional,
          cursor,
          config,
        )
      }
    } else {
      cursor = renderRecomendacoes(
        doc,
        'Atividade não realizada',
        relatorio.AjustesNutricionaisObs,
        cursor,
        config,
      )
    }
  }

  return cursor
}

function planejamentoVolumoso(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    relatorio.CotacaoInsumoVolumoso ||
    relatorio.ColetaDoSolo ||
    relatorio.AmostrasAnalise ||
    relatorio.LaudoAnalise ||
    relatorio.RecomendacaoVolumoso ||
    relatorio.PlanejamentoVolumosoObs
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Planejamento de volumoso', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    if (relatorio.PlanejamentoVolumoso) {
      if (relatorio.CotacaoInsumoVolumoso) {
        cursor = renderIdentificacao(
          doc,
          'Identificação de insumos cotados para plantio:',
          'Nenhum insumo identificado',
          relatorio.ListaInsumosVolumosos,
          cursor,
          config,
        )
      }
      if (relatorio.ColetaDoSolo) {
        renderCheckbox(
          doc,
          'Realizar coleta de solo das áreas de plantio para análise',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.AmostrasAnalise) {
        renderCheckbox(doc, 'Enviar amostras para análise', cursor, config)
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.LaudoAnalise) {
        renderCheckbox(
          doc,
          'Enviar o laudo da análise assim que estiver pronto',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      cursor = renderRecomendacoes(
        doc,
        'Outras recomendações:',
        relatorio.RecomendacaoVolumoso,
        cursor,
        config,
      )
    } else {
      cursor = renderRecomendacoes(
        doc,
        'Atividade não realizada',
        relatorio.PlanejamentoVolumosoObs,
        cursor,
        config,
      )
    }
  }

  return cursor
}

function manejoSanitario(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    (relatorio.sanitario &&
      (relatorio.sanitario.Vacinas ||
        relatorio.sanitario.Vacinas ||
        relatorio.sanitario.Pediluvio ||
        relatorio.sanitario.Pediluvio ||
        relatorio.sanitario.Vermifugacao ||
        relatorio.sanitario.Vermifugacao ||
        relatorio.sanitario.ControleCarrapatos ||
        relatorio.sanitario.ControleCarrapatos ||
        relatorio.sanitario.Obs)) ||
    relatorio.SanitarioObs
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Manejo sanitário', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    if (relatorio.Sanitario) {
      cursor = renderIdentificacaoSanitario(
        doc,
        'Vacinas: (Vacina | Dosagem | Categoria)',
        'Nenhuma vacina inserida',
        relatorio.sanitario.Vacinas,
        cursor,
        config,
      )

      cursor = renderIdentificacaoSanitario(
        doc,
        'Manter utilização do pedilúvio: (Produto | Frequência | Concentração)',
        'Nenhum produto inserido',
        relatorio.sanitario.Pediluvio,
        cursor,
        config,
      )

      cursor = renderIdentificacaoSanitario(
        doc,
        'Vermifugação: (Produto | Dosagem | Via de aplicação)',
        'Nenhum produto inserido',
        relatorio.sanitario.Vermifugacao,
        cursor,
        config,
      )

      cursor = renderIdentificacaoSanitario(
        doc,
        'Carrapaticidas: (Produto | Dosagem | Via de aplicação)',
        'Nenhum produto inserido',
        relatorio.sanitario.ControleCarrapatos,
        cursor,
        config,
      )

      cursor = renderRecomendacoes(
        doc,
        'Outras recomendações:',
        relatorio.sanitario.Obs,
        cursor,
        config,
      )
    } else {
      cursor = renderRecomendacoes(
        doc,
        'Atividade não realizada',
        relatorio.SanitarioObs,
        cursor,
        config,
      )
    }
  }

  return cursor
}

function acompanhamentoOrdenha(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    (relatorio.Ordenha &&
      (relatorio.RealizarLinhaOrdenha ||
        relatorio.TesteCaneca ||
        relatorio.PreDippingOrdenha ||
        (relatorio.PapelToalha && relatorio.PapelToalha == 1) ||
        relatorio.PosDipping ||
        relatorio.ComprarTermometro ||
        relatorio.ProcedimentoOrdenha ||
        relatorio.ColetaLeiteCCS ||
        relatorio.ColetaLeiteMicrobiologica ||
        relatorio.UtilizarAntibioticoSecas ||
        relatorio.UtilizarSelanteIntramamario ||
        relatorio.ManterAreaDescanso ||
        relatorio.CercarAreaDescanso ||
        relatorio.RecomendacaoOrdenha)) ||
    relatorio.OrdenhaObs
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Informações de ordenha', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    if (relatorio.Ordenha) {
      if (relatorio.RealizarLinhaOrdenha) {
        renderCheckbox(
          doc,
          'Realizar linha de ordenha proposta em anexo',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.TesteCaneca) {
        renderCheckbox(
          doc,
          'Fazer o teste da caneca em todas as ordenhas',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.PreDippingOrdenha && relatorio.PreDippingOrdenha == 1) {
        renderCheckbox(
          doc,
          'Realizar a aplicação do pré dipping em todas as vacas',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.PapelToalha) {
        renderCheckbox(
          doc,
          'Utilizar papel toalha para secagem dos tetos',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.PosDipping) {
        renderCheckbox(
          doc,
          'Realizar a aplicação do pós dipping em todas as vacas',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.ComprarTermometro) {
        renderCheckbox(
          doc,
          'Comprar termômetro para aferição da temperatura da água (70°C)',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.ProcedimentoOrdenha) {
        renderCheckbox(
          doc,
          'Seguir procedimentos de ordenha conforme procedimento operacional padrão em anexo',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.ColetaLeiteCCS) {
        renderCheckbox(
          doc,
          'Realizar coleta de leite para análise individual da CCS',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.ColetaLeiteMicrobiologica) {
        renderCheckbox(
          doc,
          'Realizar coleta de leite para análise microbiológica do leite',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.UtilizarAntibioticoSecas) {
        renderCheckbox(
          doc,
          'Utilizar antibiótico vaca seca para secagem das vacas (indicação do produto)',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.UtilizarSelanteIntramamario) {
        renderCheckbox(
          doc,
          'Utilizar selante intramamário para secagem das vacas (indicação do produto)',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.ManterAreaDescanso) {
        renderCheckbox(
          doc,
          'Manter limpas as áreas de descanso das vacas',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.CercarAreaDescanso) {
        renderCheckbox(
          doc,
          'Cercar as áreas de descanso com acúmulo de barro',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      cursor = renderRecomendacoes(
        doc,
        'Outras recomendações:',
        relatorio.RecomendacaoOrdenha,
        cursor,
        config,
      )
    } else {
      cursor = renderRecomendacoes(
        doc,
        'Atividade não realizada',
        relatorio.OrdenhaObs,
        cursor,
        config,
      )
    }
  }

  return cursor
}

function criaRecria(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    (relatorio.criarecria &&
      (relatorio.criarecria.NovilhasComPeso ||
        relatorio.criarecria.BezerrasDesmamar ||
        relatorio.PesagemRecria ||
        relatorio.criarecria.Obs)) ||
    relatorio.CriaRecriaObs
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Acompanhamento da cria/recria', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    if (relatorio.CriaRecria) {
      cursor = renderIdentificacao(
        doc,
        'Novilhas aptas à inseminação:',
        'Nenhum animal identificado',
        relatorio.criarecria.NovilhasComPeso,
        cursor,
        config,
      )

      cursor = renderIdentificacao(
        doc,
        'Bezerras a desmamar:',
        'Nenhum animal identificado',
        relatorio.criarecria.BezerrasDesmamar,
        cursor,
        config,
      )

      if (relatorio.PesagemRecria) {
        renderCheckbox(
          doc,
          'Realizar pesagem mensal dos animais em recria para acompanhar ganho de peso',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      cursor = renderRecomendacoes(
        doc,
        'Outras recomendações:',
        relatorio.criarecria.Obs,
        cursor,
        config,
      )
    } else {
      cursor = renderRecomendacoes(
        doc,
        'Atividade não realizada',
        relatorio.CriaRecriaObs,
        cursor,
        config,
      )
    }
  }

  return cursor
}

function clinico(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    (relatorio.Clinico &&
      (relatorio.Clinico.AnimaisTratados ||
        relatorio.Clinico.AnimaisTratados ||
        relatorio.Clinico.Tratamento ||
        relatorio.Clinico.Tratamento ||
        relatorio.Clinico.Obs)) ||
    relatorio.ClinicoObs
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Atendimento clínico', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    if (relatorio.Clinico) {
      cursor = renderIdentificacao(
        doc,
        'Animais tratados:',
        'Nenhuma animal identificado',
        relatorio.Clinico.AnimaisTratados,
        cursor,
        config,
      )

      cursor = renderIdentificacaoSanitario(
        doc,
        'Tratamento: (Medicação | Dosagem | Via de aplicação | Duração do tratamento)',
        'Nenhum tratamento',
        relatorio.Clinico.Tratamento,
        cursor,
        config,
      )

      cursor = renderRecomendacoes(
        doc,
        'Outras recomendações:',
        relatorio.Clinico.Obs,
        cursor,
        config,
      )
    } else {
      cursor = renderRecomendacoes(
        doc,
        'Atividade não realizada',
        relatorio.ClinicoObs,
        cursor,
        config,
      )
    }
  }

  return cursor
}

function discussaoZootecnico(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (relatorio.DiscussaoZootecnico) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Discussões de indicadores zootécnicos', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    cursor = renderDiscussao(
      doc,
      relatorio.DiscussaoZootecnicoText,
      cursor,
      config,
    )
  }

  return cursor
}

function discussaoEconomico(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (relatorio.DiscussaoEconomico) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Discussões de indicadores econômicos', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    cursor = renderDiscussao(doc, relatorio.DiscussaoEconomico, cursor, config)
  }

  return cursor
}

function discussaoMercado(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (relatorio.DiscussaoMercado) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text(
        'Discussões de indicadores de mercado (preço do leite, insumos, venda/compra de animais)',
        startX,
        cursor,
      )
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    cursor = renderDiscussao(doc, relatorio.DiscussaoMercado, cursor, config)
  }

  return cursor
}

function planejamentoEstrategico(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (relatorio.PlanejamentoEstrategico) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Planejamento estratégico', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    cursor = renderDiscussao(
      doc,
      relatorio.PlanejamentoEstrategicoText,
      cursor,
      config,
    )
  }

  return cursor
}

// eslint-disable-next-line no-unused-vars
function relatoriosAnexo(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    relatorio.AcompanhamentoRebanho ||
    relatorio.AnaliseReprodutiva ||
    relatorio.PrevisaoPartos ||
    relatorio.VacasSecar ||
    relatorio.DivisaoLotes ||
    relatorio.AnaliseQualidade ||
    relatorio.CcsIndividual ||
    relatorio.LinhaOrdenha ||
    relatorio.FluxoCaixa ||
    relatorio.CustoProducaoMensal ||
    relatorio.CustoProducaoAnual
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Relatórios em anexo', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    if (relatorio.AcompanhamentoRebanho) {
      renderCheckbox(doc, 'Acompanhamento de rebanho', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.AnaliseReprodutiva) {
      renderCheckbox(doc, 'Análise reprodutiva', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.PrevisaoPartos) {
      renderCheckbox(doc, 'Previsão de partos', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.VacasSecar) {
      renderCheckbox(doc, 'Vacas a secar', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.DivisaoLotes) {
      renderCheckbox(doc, 'Divisão de lotes e arraçoamento', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.AnaliseQualidade) {
      renderCheckbox(doc, 'Análise de qualidade', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.CcsIndividual) {
      renderCheckbox(doc, 'CCS individual', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.LinhaOrdenha) {
      renderCheckbox(doc, 'Linha de ordenha', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.FluxoCaixa) {
      renderCheckbox(doc, 'Fluxo de caixa', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.CustoProducaoMensal) {
      renderCheckbox(doc, 'Custo de produção mensal', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.CustoProducaoAnual) {
      renderCheckbox(doc, 'Custo de produção anual', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
  }

  return cursor
}

function proximaVisitaAssinaturas(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    tableLimit,
    heightLimit,
    margin,
    startX,
    smallGutterY,
    line,
  } = config

  cursor += config.boxHeight
  if (cursor + boxHeight * 7 > heightLimit) {
    doc.addPage()
    cursor = margin
  }
  const proximaVisita = relatorio.DataProximaVisita
    ? formatDate(relatorio.DataProximaVisita, 'DD/MM/YYYY')
    : '-'

  doc.text('Data da próxima visita', tableLimit / 2 - 30, cursor + boxHeight)
  doc.text(
    proximaVisita,
    tableLimit / 2 - 5,
    cursor + boxHeight + smallBoxHeight,
  )

  cursor += boxHeight * 2

  const produtor = new Image()
  produtor.src = relatorio.assinaturas.AssinaturaProdutor
  doc.addImage(
    produtor,
    'PNG',
    startX + 50,
    cursor - 140,
    100,
    145,
    '',
    'FAST',
    270,
  )

  if (relatorio.assinaturas.AssinaturaConsultor) {
    const consultor = new Image()
    consultor.src = relatorio.assinaturas.AssinaturaConsultor
    doc.addImage(
      consultor,
      'PNG',
      tableLimit - 180,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  }

  doc.rect(startX + 25, cursor + 100, tableLimit / 3, line, 'F')
  doc.text(
    relatorio.assinaturas.NomeResponsavel
      ? relatorio.assinaturas.NomeResponsavel
      : relatorio.fazenda.produtor.Nome,
    tableLimit / 3.9,
    cursor + 100 + smallGutterY,
    null,
    null,
    'center',
  )
  doc.text(
    'Assinatura ' +
      (relatorio.assinaturas.NomeResponsavel
        ? 'do responsável'
        : 'do(a) produtor(a)'),
    tableLimit / 3.9,
    cursor + 115 + smallGutterY,
    null,
    null,
    'center',
  )

  doc.rect(startX + 320, cursor + 100, tableLimit / 3, line, 'F')
  doc.text(
    relatorio.consultor.pessoafisica.Nome,
    startX + 420,
    cursor + 100 + smallGutterY,
    null,
    null,
    'center',
  )
  doc.text(
    'Assinatura consultor(a)',
    startX + 420,
    cursor + 115 + smallGutterY,
    null,
    null,
    'center',
  )

  cursor += boxHeight * 2

  return cursor
}

function rodapeImpressao(doc, config, cursor, text) {
  const { boxHeight, heightLimit, margin } = config
  const docWidth = doc.internal.pageSize.width
  const texto =
    utilsRelatorio.getDataAtual() +
    '       ' +
    `${ENV_APP.relUri}` +
    '        Pag. ' +
    text

  if (cursor + boxHeight * 2 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  doc.text(texto, docWidth / 2, heightLimit + 9, 'center')
}

export default data => {
  return new Promise(function (resolve, reject) {
    const MARGIN = 25
    const realStartY = 95
    var doc = new JsPDF({
      format: 'a4',
      compress: true,
      unit: 'pt',
    })
    var config = {
      startY: realStartY,
      startX: MARGIN,
      smallGutterY: 12,
      smallGutterX: 6,
      line: 0.3,
      tableLimit: doc.internal.pageSize.width - MARGIN - MARGIN,
      heightLimit: doc.internal.pageSize.height - MARGIN,
      margin: MARGIN,
      lineColor: '#000',
      boxHeight: 27,
      smallBoxHeight: 18,
      gutterX: 14,
      gutterY: 23,
      smallFontSize: 6.5,
      fontSize: 10,
      largefontSize: 16,
      mediumFontSize: 12,
      logo: 'img/' + `${ENV_APP.imgFileLogin}`,
      bodyStarY: realStartY + 25 * 8.5,
    }

    try {
      HtmlToCanvas(document.querySelector('#chart-rebanho')).then(canvas => {
        var dataURL = canvas.toDataURL()
        doc.addImage(
          dataURL,
          'PNG',
          config.startX * 2.8,
          config.startY + config.boxHeight * 8 + 20,
          456,
          200,
        )
        var cursor = 0
        header(doc, config, data)
        subHeader(doc, data, config)
        config.startY = config.startY + config.boxHeight * 3 + 20
        indicadoresQualidade(doc, data, config)
        config.startY = config.startY + config.boxHeight * 4 + 20
        cursor = composicaoRebanho(doc, data, config)
        config.startY = cursor + config.boxHeight

        cursor = coletaZootecnico(doc, data, config, cursor)
        cursor = coletaFinanceiro(doc, data, config, cursor)
        cursor = manejoReprodutivo(doc, data, config, cursor)
        cursor = ajustesNutricionais(doc, data, config, cursor)
        cursor = planejamentoVolumoso(doc, data, config, cursor)
        cursor = acompanhamentoOrdenha(doc, data, config, cursor)
        cursor = manejoSanitario(doc, data, config, cursor)
        cursor = criaRecria(doc, data, config, cursor)
        cursor = clinico(doc, data, config, cursor)
        cursor = discussaoZootecnico(doc, data, config, cursor)
        cursor = discussaoEconomico(doc, data, config, cursor)
        cursor = discussaoMercado(doc, data, config, cursor)
        cursor = planejamentoEstrategico(doc, data, config, cursor)
        cursor = proximaVisitaAssinaturas(doc, data, config, cursor)
        const pageCount = doc.internal.getNumberOfPages()

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i)
          rodapeImpressao(
            doc,
            config,
            cursor + config.boxHeight,
            String(i) + ' de ' + String(pageCount),
          )
        }
        doc
          .save(
            `relatorio-visita-${data.NumeroVisita}-${data.fazenda.pessoajuridica.NomeFantasia}.pdf`,
            { returnPromise: true },
          )
          .then(() => {
            return resolve()
          })
      })
    } catch (err) {
      reject(err)
    }
  })
}
