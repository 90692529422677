var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.relatorio.ManterAttFinanceiro ||
    _vm.relatorio.FinanciamentoAVenver ||
    _vm.relatorio.ImplementosAVencer ||
    _vm.relatorio.EmprestimosAVencer ||
    _vm.relatorio.ArmazenarNotasDespesas ||
    _vm.relatorio.RecomendacaoFinanceiro ||
    _vm.relatorio.Step5Atividade1 ||
    _vm.relatorio.Step5Atividade2 ||
    _vm.relatorio.Step5Atividade3 ||
    _vm.relatorio.Step5Atividade4 ||
    _vm.relatorio.Step5Atividade5 ||
    _vm.relatorio.Step5Atividade6 ||
    _vm.relatorio.Step5OutrasAtividades ||
    _vm.relatorio.Step5OutrasAtividadesText ||
    _vm.relatorio.ColetaFinanceiroObs
  )?_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Coleta e atualização de dados financeiros")]),_c('v-card-text',[(_vm.relatorio.ColetaFinanceiro)?_c('v-row',{staticClass:"pa-3"},[(
          _vm.relatorio.Step5Atividade1 ||
          _vm.relatorio.Step5Atividade2 ||
          _vm.relatorio.Step5Atividade3 ||
          _vm.relatorio.Step5Atividade4 ||
          _vm.relatorio.Step5Atividade5 ||
          _vm.relatorio.Step5Atividade6
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Atividades realizadas")]),(_vm.relatorio.Step5Atividade1)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"vulue-title"},[_vm._v("Lançamento de RD (receitas e despesas) no sistema ")])]):_vm._e(),(_vm.relatorio.Step5Atividade2)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"vulue-title"},[_vm._v("Lançamento do IR (inventário de recursos) no sistema ")])]):_vm._e(),(_vm.relatorio.Step5Atividade3)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"vulue-title"},[_vm._v("Orientação de como fazer as anotações no caderno financeiro ")])]):_vm._e(),(_vm.relatorio.Step5Atividade4)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"vulue-title"},[_vm._v("Orientação de como fazer lançamento no sistema ")])]):_vm._e(),(_vm.relatorio.Step5Atividade5)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"vulue-title"},[_vm._v("Orientação de como visualizar os relatórios no sistema ")])]):_vm._e(),(_vm.relatorio.Step5Atividade6)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"vulue-title"},[_vm._v("Discussão dos indicadores econômicos ")])]):_vm._e(),(_vm.relatorio.Step5OutrasAtividades)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras Atividades: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.Step5OutrasAtividadesText || '-'))])]):_vm._e()],1):_vm._e(),(
          _vm.relatorio.ManterAttFinanceiro ||
          _vm.relatorio.FinanciamentoAVenver ||
          _vm.relatorio.ImplementosAVencer ||
          _vm.relatorio.EmprestimosAVencer ||
          _vm.relatorio.ArmazenarNotasDespesas ||
          _vm.relatorio.RecomendacaoFinanceiro
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Recomendações")]),(_vm.relatorio.ManterAttFinanceiro)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"vulue-title"},[_vm._v("Manter atualizações dos lançamentos financeiros em dia ")])]):_vm._e(),(_vm.relatorio.FinanciamentoAVenver)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"vulue-title"},[_vm._v("Fazer o levantamento dos financiamentos a vencer para serem lançados no aplicativo ")])]):_vm._e(),(_vm.relatorio.ImplementosAVencer)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"vulue-title"},[_vm._v("Fazer o levantamento das parcelas dos implementos a vencer para serem lançados no aplicativo ")])]):_vm._e(),(_vm.relatorio.EmprestimosAVencer)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"vulue-title"},[_vm._v("Fazer o levantamento das parcelas dos empréstimos a vencer para serem lançados no aplicativo ")])]):_vm._e(),(_vm.relatorio.ArmazenarNotasDespesas)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"vulue-title"},[_vm._v("Armazenar todas as notas de despesas até que sejam lançadas no aplicativo ")])]):_vm._e(),(_vm.relatorio.RecomendacaoFinanceiro)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras recomendações: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.RecomendacaoFinanceiro || '-'))])]):_vm._e()],1):_vm._e()],1):_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('span',{staticClass:"key-title"},[_vm._v("Atividade não realizada ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.ColetaFinanceiroObs || '-'))])])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }