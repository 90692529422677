<template>
  <v-card
    v-if="
      relatorio.ManterAttFinanceiro ||
      relatorio.FinanciamentoAVenver ||
      relatorio.ImplementosAVencer ||
      relatorio.EmprestimosAVencer ||
      relatorio.ArmazenarNotasDespesas ||
      relatorio.RecomendacaoFinanceiro ||
      relatorio.Step5Atividade1 ||
      relatorio.Step5Atividade2 ||
      relatorio.Step5Atividade3 ||
      relatorio.Step5Atividade4 ||
      relatorio.Step5Atividade5 ||
      relatorio.Step5Atividade6 ||
      relatorio.Step5OutrasAtividades ||
      relatorio.Step5OutrasAtividadesText ||
      relatorio.ColetaFinanceiroObs
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left"
      >Coleta e atualização de dados financeiros</v-card-title
    >
    <v-card-text>
      <v-row v-if="relatorio.ColetaFinanceiro" class="pa-3">
        <v-col
          v-if="
            relatorio.Step5Atividade1 ||
            relatorio.Step5Atividade2 ||
            relatorio.Step5Atividade3 ||
            relatorio.Step5Atividade4 ||
            relatorio.Step5Atividade5 ||
            relatorio.Step5Atividade6
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Atividades realizadas</span>
          <v-col v-if="relatorio.Step5Atividade1" cols="12">
            <span class="vulue-title"
              >Lançamento de RD (receitas e despesas) no sistema
            </span>
          </v-col>
          <v-col v-if="relatorio.Step5Atividade2" cols="12">
            <span class="vulue-title"
              >Lançamento do IR (inventário de recursos) no sistema
            </span>
          </v-col>
          <v-col v-if="relatorio.Step5Atividade3" cols="12">
            <span class="vulue-title"
              >Orientação de como fazer as anotações no caderno financeiro
            </span>
          </v-col>
          <v-col v-if="relatorio.Step5Atividade4" cols="12">
            <span class="vulue-title"
              >Orientação de como fazer lançamento no sistema
            </span>
          </v-col>
          <v-col v-if="relatorio.Step5Atividade5" cols="12">
            <span class="vulue-title"
              >Orientação de como visualizar os relatórios no sistema
            </span>
          </v-col>
          <v-col v-if="relatorio.Step5Atividade6" cols="12">
            <span class="vulue-title"
              >Discussão dos indicadores econômicos
            </span>
          </v-col>
          <v-col v-if="relatorio.Step5OutrasAtividades" cols="12">
            <span class="key-title">Outras Atividades: </span> <br />
            <span class="value-title">{{
              relatorio.Step5OutrasAtividadesText || '-'
            }}</span>
          </v-col>
        </v-col>

        <v-col
          v-if="
            relatorio.ManterAttFinanceiro ||
            relatorio.FinanciamentoAVenver ||
            relatorio.ImplementosAVencer ||
            relatorio.EmprestimosAVencer ||
            relatorio.ArmazenarNotasDespesas ||
            relatorio.RecomendacaoFinanceiro
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Recomendações</span>
          <v-col v-if="relatorio.ManterAttFinanceiro" cols="12">
            <span class="vulue-title"
              >Manter atualizações dos lançamentos financeiros em dia
            </span>
          </v-col>
          <v-col v-if="relatorio.FinanciamentoAVenver" cols="12">
            <span class="vulue-title"
              >Fazer o levantamento dos financiamentos a vencer para serem
              lançados no aplicativo
            </span>
          </v-col>
          <v-col v-if="relatorio.ImplementosAVencer" cols="12">
            <span class="vulue-title"
              >Fazer o levantamento das parcelas dos implementos a vencer para
              serem lançados no aplicativo
            </span>
          </v-col>
          <v-col v-if="relatorio.EmprestimosAVencer" cols="12">
            <span class="vulue-title"
              >Fazer o levantamento das parcelas dos empréstimos a vencer para
              serem lançados no aplicativo
            </span>
          </v-col>
          <v-col v-if="relatorio.ArmazenarNotasDespesas" cols="12">
            <span class="vulue-title"
              >Armazenar todas as notas de despesas até que sejam lançadas no
              aplicativo
            </span>
          </v-col>
          <v-col v-if="relatorio.RecomendacaoFinanceiro" cols="12">
            <span class="key-title">Outras recomendações: </span> <br />
            <span class="value-title">{{
              relatorio.RecomendacaoFinanceiro || '-'
            }}</span>
          </v-col>
        </v-col>
      </v-row>
      <v-row v-else justify="center">
        <v-col>
          <span class="key-title">Atividade não realizada </span> <br />
          <span class="value-title">{{
            relatorio.ColetaFinanceiroObs || '-'
          }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ColetaFinanceiro',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
  line-height: 40px;
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
