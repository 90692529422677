var render = function render(){var _vm=this,_c=_vm._self._c;return (
    (_vm.relatorio.ImagensManejoReprodutivoVacas != null &&
      _vm.relatorio.ImagensManejoReprodutivoVacas.length) ||
    (_vm.relatorio.ImagensManejoReprodutivoNovilhas != null &&
      _vm.relatorio.ImagensManejoReprodutivoNovilhas.length) ||
    (_vm.relatorio.ImagensAjustesNutricionais != null &&
      _vm.relatorio.ImagensAjustesNutricionais.length) ||
    (_vm.relatorio.ImagensPlanejamentoVolumoso != null &&
      _vm.relatorio.ImagensPlanejamentoVolumoso.length) ||
    (_vm.relatorio.ImagensAcompanhamentoDeOrdenha != null &&
      _vm.relatorio.ImagensAcompanhamentoDeOrdenha.length) ||
    (_vm.relatorio.ImagensManejoSanitario != null &&
      _vm.relatorio.ImagensManejoSanitario.length) ||
    (_vm.relatorio.ImagensAcompanhamentoCriaRecria != null &&
      _vm.relatorio.ImagensAcompanhamentoCriaRecria.length) ||
    (_vm.relatorio.ImagensAtendimentoClinico != null &&
      _vm.relatorio.ImagensAtendimentoClinico.length) ||
    (_vm.relatorio.ImagensDiscussaoEconomico != null &&
      _vm.relatorio.ImagensDiscussaoEconomico.length) ||
    (_vm.relatorio.ImagensDiscussaoMercado != null &&
      _vm.relatorio.ImagensDiscussaoMercado.length) ||
    (_vm.relatorio.ImagensDiscussaoZootecnico != null &&
      _vm.relatorio.ImagensDiscussaoZootecnico.length) ||
    (_vm.relatorio.ImagensPlanejamentoEstrategico != null &&
      _vm.relatorio.ImagensPlanejamentoEstrategico.length)
  )?_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Imagens em anexo")]),_c('v-card-text',[_c('v-row',{staticClass:"pa-3"},[(
          _vm.relatorio.ImagensManejoReprodutivoVacas != null &&
          _vm.relatorio.ImagensManejoReprodutivoVacas.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens do manejo reprodutivo de vacas")]),_c('br'),_vm._l((_vm.relatorio.ImagensManejoReprodutivoVacas),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"600px","max-height":"600px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e(),(
          _vm.relatorio.ImagensManejoReprodutivoNovilhas != null &&
          _vm.relatorio.ImagensManejoReprodutivoNovilhas.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens do manejo reprodutivo de novilhas")]),_c('br'),_vm._l((_vm.relatorio.ImagensManejoReprodutivoNovilhas),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"600px","max-height":"600px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e(),(
          _vm.relatorio.ImagensAjustesNutricionais != null &&
          _vm.relatorio.ImagensAjustesNutricionais.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens do ajustes nutricionais")]),_c('br'),_vm._l((_vm.relatorio.ImagensAjustesNutricionais),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"600px","max-height":"600px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e(),(
          _vm.relatorio.ImagensPlanejamentoVolumoso != null &&
          _vm.relatorio.ImagensPlanejamentoVolumoso.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens do planejamento volumoso")]),_c('br'),_vm._l((_vm.relatorio.ImagensPlanejamentoVolumoso),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"600px","max-height":"600px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e(),(
          _vm.relatorio.ImagensAcompanhamentoDeOrdenha != null &&
          _vm.relatorio.ImagensAcompanhamentoDeOrdenha.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens do acompanhamento de ordenha")]),_c('br'),_vm._l((_vm.relatorio.ImagensAcompanhamentoDeOrdenha),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"600px","max-height":"600px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e(),(
          _vm.relatorio.ImagensManejoSanitario != null &&
          _vm.relatorio.ImagensManejoSanitario.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens do manejo sanitário")]),_c('br'),_vm._l((_vm.relatorio.ImagensManejoSanitario),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"600px","max-height":"600px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e(),(
          _vm.relatorio.ImagensAcompanhamentoCriaRecria != null &&
          _vm.relatorio.ImagensAcompanhamentoCriaRecria.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens do acompanhamento cria/recria")]),_c('br'),_vm._l((_vm.relatorio.ImagensAcompanhamentoCriaRecria),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"600px","max-height":"600px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e(),(
          _vm.relatorio.ImagensAtendimentoClinico != null &&
          _vm.relatorio.ImagensAtendimentoClinico.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens do atendimento clínico")]),_c('br'),_vm._l((_vm.relatorio.ImagensAtendimentoClinico),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"600px","max-height":"600px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e(),(
          _vm.relatorio.ImagensDiscussaoEconomico != null &&
          _vm.relatorio.ImagensDiscussaoEconomico.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens discussões de indicadores econômicos ")]),_c('br'),_vm._l((_vm.relatorio.ImagensDiscussaoEconomico),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"600px","max-height":"600px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e(),(
          _vm.relatorio.ImagensDiscussaoMercado != null &&
          _vm.relatorio.ImagensDiscussaoMercado.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens discussões de indicadores de mercado")]),_c('br'),_vm._l((_vm.relatorio.ImagensDiscussaoMercado),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"600px","max-height":"600px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e(),(
          _vm.relatorio.ImagensDiscussaoZootecnico != null &&
          _vm.relatorio.ImagensDiscussaoZootecnico.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens discussões de indicadores zootécnicos")]),_c('br'),_vm._l((_vm.relatorio.ImagensDiscussaoZootecnico),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"600px","max-height":"600px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e(),(
          _vm.relatorio.ImagensPlanejamentoEstrategico != null &&
          _vm.relatorio.ImagensPlanejamentoEstrategico.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Imagens planejamento estratégico")]),_c('br'),_vm._l((_vm.relatorio.ImagensPlanejamentoEstrategico),function(imagem,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(imagem.id)?_c('v-img',{attrs:{"src":_vm.getImagem(imagem.id),"contain":"","max-width":"600px","max-height":"600px","alt":"imagem"}}):_vm._e()],1)})],2):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }