<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          style="color: white; border-radius: 8px"
          color="primary"
          dark
          v-bind="attrs"
          @click="print()"
          v-on="on"
        >
          <v-icon left>mdi-printer</v-icon>
          <span style="font-size: 16px; font-weight: 600">DOWNLOAD PDF</span>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          Aguarde enquanto o PDF está sendo gerado...
        </v-card-title>
        <div align="center" justify="center" class="mt-4">
          <v-progress-circular
            :width="4"
            :size="50"
            color="green"
            indeterminate
          ></v-progress-circular>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="exit"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { DashboardBase } from '../../../services/reports/dashboard/DashboardBase'

export default {
  name: 'DashboardPrint',

  props: {
    title: {
      type: String,
      default: 'Dashboard',
      required: true,
    },

    imgs: {
      type: Array,
      default: () => [],
      required: false,
    },

    imgsTitles: {
      type: Array,
      default: () => [],
      required: false,
    },
  },

  data() {
    return {
      dialog: false,

      images: [],
      labels: [],
    }
  },

  methods: {
    setData() {
      if (this.imgs.length <= 0) {
        this.images = document.querySelectorAll('div.chart-table')
      } else {
        this.images = this.imgs
      }

      if (this.imgsTitles.length <= 0) {
        const texts = document.querySelectorAll(
          'h2.text-xl-h4, h2.font-weight-medium',
        )

        for (const t of texts) {
          this.labels.push(t.innerText)
        }
      } else {
        this.labels = this.imgsTitles
      }
    },

    async print() {
      this.dialog = true

      this.setData()

      try {
        const dashboard = new DashboardBase({
          title: this.title || 'Dashboard',
          imgs: this.images,
          imgsTitles: this.labels,
          controller: this.controller,
        })

        await dashboard.render()

        await dashboard.save({})
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Não foi possível gerar o PDF.',
        })
      }

      this.dialog = false
    },

    exit() {
      this.dialog = false
    },
  },
}
</script>
