<template>
  <v-card
    v-if="
      (relatorio.ImagensManejoReprodutivoVacas != null &&
        relatorio.ImagensManejoReprodutivoVacas.length) ||
      (relatorio.ImagensManejoReprodutivoNovilhas != null &&
        relatorio.ImagensManejoReprodutivoNovilhas.length) ||
      (relatorio.ImagensAjustesNutricionais != null &&
        relatorio.ImagensAjustesNutricionais.length) ||
      (relatorio.ImagensPlanejamentoVolumoso != null &&
        relatorio.ImagensPlanejamentoVolumoso.length) ||
      (relatorio.ImagensAcompanhamentoDeOrdenha != null &&
        relatorio.ImagensAcompanhamentoDeOrdenha.length) ||
      (relatorio.ImagensManejoSanitario != null &&
        relatorio.ImagensManejoSanitario.length) ||
      (relatorio.ImagensAcompanhamentoCriaRecria != null &&
        relatorio.ImagensAcompanhamentoCriaRecria.length) ||
      (relatorio.ImagensAtendimentoClinico != null &&
        relatorio.ImagensAtendimentoClinico.length) ||
      (relatorio.ImagensDiscussaoEconomico != null &&
        relatorio.ImagensDiscussaoEconomico.length) ||
      (relatorio.ImagensDiscussaoMercado != null &&
        relatorio.ImagensDiscussaoMercado.length) ||
      (relatorio.ImagensDiscussaoZootecnico != null &&
        relatorio.ImagensDiscussaoZootecnico.length) ||
      (relatorio.ImagensPlanejamentoEstrategico != null &&
        relatorio.ImagensPlanejamentoEstrategico.length)
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left">Imagens em anexo</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="
            relatorio.ImagensManejoReprodutivoVacas != null &&
            relatorio.ImagensManejoReprodutivoVacas.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Imagens do manejo reprodutivo de vacas</span>
          <br />
          <v-chip
            v-for="(imagem, index) in relatorio.ImagensManejoReprodutivoVacas"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="600px"
              max-height="600px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ImagensManejoReprodutivoNovilhas != null &&
            relatorio.ImagensManejoReprodutivoNovilhas.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Imagens do manejo reprodutivo de novilhas</span
          >
          <br />
          <v-chip
            v-for="(
              imagem, index
            ) in relatorio.ImagensManejoReprodutivoNovilhas"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="600px"
              max-height="600px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ImagensAjustesNutricionais != null &&
            relatorio.ImagensAjustesNutricionais.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Imagens do ajustes nutricionais</span>
          <br />
          <v-chip
            v-for="(imagem, index) in relatorio.ImagensAjustesNutricionais"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="600px"
              max-height="600px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ImagensPlanejamentoVolumoso != null &&
            relatorio.ImagensPlanejamentoVolumoso.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Imagens do planejamento volumoso</span>
          <br />
          <v-chip
            v-for="(imagem, index) in relatorio.ImagensPlanejamentoVolumoso"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="600px"
              max-height="600px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ImagensAcompanhamentoDeOrdenha != null &&
            relatorio.ImagensAcompanhamentoDeOrdenha.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Imagens do acompanhamento de ordenha</span>
          <br />
          <v-chip
            v-for="(imagem, index) in relatorio.ImagensAcompanhamentoDeOrdenha"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="600px"
              max-height="600px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ImagensManejoSanitario != null &&
            relatorio.ImagensManejoSanitario.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Imagens do manejo sanitário</span>
          <br />
          <v-chip
            v-for="(imagem, index) in relatorio.ImagensManejoSanitario"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="600px"
              max-height="600px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ImagensAcompanhamentoCriaRecria != null &&
            relatorio.ImagensAcompanhamentoCriaRecria.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Imagens do acompanhamento cria/recria</span>
          <br />
          <v-chip
            v-for="(imagem, index) in relatorio.ImagensAcompanhamentoCriaRecria"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="600px"
              max-height="600px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ImagensAtendimentoClinico != null &&
            relatorio.ImagensAtendimentoClinico.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Imagens do atendimento clínico</span>
          <br />
          <v-chip
            v-for="(imagem, index) in relatorio.ImagensAtendimentoClinico"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="600px"
              max-height="600px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ImagensDiscussaoEconomico != null &&
            relatorio.ImagensDiscussaoEconomico.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Imagens discussões de indicadores econômicos
          </span>
          <br />
          <v-chip
            v-for="(imagem, index) in relatorio.ImagensDiscussaoEconomico"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="600px"
              max-height="600px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ImagensDiscussaoMercado != null &&
            relatorio.ImagensDiscussaoMercado.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Imagens discussões de indicadores de mercado</span
          >
          <br />
          <v-chip
            v-for="(imagem, index) in relatorio.ImagensDiscussaoMercado"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="600px"
              max-height="600px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ImagensDiscussaoZootecnico != null &&
            relatorio.ImagensDiscussaoZootecnico.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Imagens discussões de indicadores zootécnicos</span
          >
          <br />
          <v-chip
            v-for="(imagem, index) in relatorio.ImagensDiscussaoZootecnico"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="600px"
              max-height="600px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ImagensPlanejamentoEstrategico != null &&
            relatorio.ImagensPlanejamentoEstrategico.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Imagens planejamento estratégico</span>
          <br />
          <v-chip
            v-for="(imagem, index) in relatorio.ImagensPlanejamentoEstrategico"
            :key="index"
            class="ma-1"
          >
            <v-img
              v-if="imagem.id"
              :src="getImagem(imagem.id)"
              contain
              max-width="600px"
              max-height="600px"
              alt="imagem"
            />
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ENV_APP } from '../../../../../env'
export default {
  name: 'ImagensAnexo',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    maskIdentify(raw) {
      return raw ? raw.split('|') : ['Nenhum animal identificado']
    },
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
