<template>
  <v-card
    v-if="
      (relatorio.Reprodutivo =
        1 &&
        ((relatorio.QtdeVacasAvaliadas != '' &&
          relatorio.QtdeVacasAvaliadas != '0') ||
          (relatorio.QtdeVacasGestantes != '' &&
            relatorio.QtdeVacasGestantes != '0') ||
          (relatorio.QtdeVacasReavaliar != '' &&
            relatorio.QtdeVacasReavaliar != '0') ||
          (relatorio.QtdeVacasVazias != '' &&
            relatorio.QtdeVacasVazias != '0') ||
          (relatorio.ListaVacasGestantes != null &&
            relatorio.ListaVacasGestantes.length != 0) ||
          (relatorio.ListaVacasReavaliar != null &&
            relatorio.ListaVacasReavaliar.length != 0) ||
          (relatorio.ListaVacasVaziasIATF != null &&
            relatorio.ListaVacasVaziasIATF.length != 0) ||
          (relatorio.ListaVacasVaziasPGF != null &&
            relatorio.ListaVacasVaziasPGF.length != 0) ||
          (relatorio.ListaVacasVaziasRecuperarCondicaoCorporal != null &&
            relatorio.ListaVacasVaziasRecuperarCondicaoCorporal.length != 0) ||
          (relatorio.ListaVacasVaziasTratamentoClinico != null &&
            relatorio.ListaVacasVaziasTratamentoClinico.length != 0) ||
          relatorio.SepararTourosVacas != '' ||
          relatorio.OutrasRecomendacoesReprodutivoVacas != '' ||
          relatorio.ReprodutivoVacasObs != '' ||
          (relatorio.QtdeNovilhasAvaliadas != '' &&
            relatorio.QtdeNovilhasAvaliadas != '0') ||
          (relatorio.QtdeNovilhasGestantes != '' &&
            relatorio.QtdeNovilhasGestantes != '0') ||
          (relatorio.QtdeNovilhasReavaliar != '' &&
            relatorio.QtdeNovilhasReavaliar != '0') ||
          (relatorio.QtdeNovilhasVazias != '' &&
            relatorio.QtdeNovilhasVazias != '0') ||
          (relatorio.ListaNovilhasGestantes != null &&
            relatorio.ListaNovilhasGestantes.length != 0) ||
          (relatorio.ListaNovilhasReavaliar != null &&
            relatorio.ListaNovilhasReavaliar.length != 0) ||
          (relatorio.ListaNovilhasVaziasIATF != null &&
            relatorio.ListaNovilhasVaziasIATF.length != 0) ||
          (relatorio.ListaNovilhasVaziasPGF != null &&
            relatorio.ListaNovilhasVaziasPGF.length != 0) ||
          (relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal != null &&
            relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal.length !=
              0) ||
          (relatorio.ListaNovilhasVaziasTratamentoClinico != null &&
            relatorio.ListaNovilhasVaziasTratamentoClinico.length != 0) ||
          relatorio.separarTouroNovilhas != '' ||
          relatorio.OutrasRecomendacoesReprodutivoNovilhas != '' ||
          relatorio.ReprodutivoNovilhasObs != '' ||
          relatorio.Step6Atividade1 ||
          relatorio.Step6Atividade2 ||
          relatorio.Step6Atividade3 ||
          relatorio.Step6Atividade4 ||
          relatorio.Step6Atividade5 ||
          relatorio.Step6OutrasAtividades ||
          relatorio.Step6OutrasAtividadesText ||
          relatorio.Step7Atividade1 ||
          relatorio.Step7Atividade2 ||
          relatorio.Step7Atividade3 ||
          relatorio.Step7Atividade4 ||
          relatorio.Step7Atividade5 ||
          relatorio.Step7OutrasAtividades ||
          relatorio.Step7OutrasAtividadesText))
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left">Manejo reprodutivo</v-card-title>
    <v-card-text>
      <v-row
        v-if="
          relatorio.QtdeVacasAvaliadas ||
          relatorio.QtdeVacasGestantes ||
          relatorio.QtdeVacasReavaliar ||
          relatorio.QtdeVacasVazias ||
          relatorio.ListaVacasGestantes ||
          relatorio.ListaVacasReavaliar ||
          relatorio.ListaVacasVaziasIATF ||
          relatorio.ListaVacasVaziasPGF ||
          relatorio.ListaVacasVaziasRecuperarCondicaoCorporal ||
          relatorio.ListaVacasVaziasTratamentoClinico ||
          relatorio.SepararTourosVacas ||
          relatorio.OutrasRecomendacoesReprodutivoVacas ||
          relatorio.ReprodutivoVacasObs ||
          relatorio.QtdeNovilhasAvaliadas ||
          relatorio.QtdeNovilhasGestantes ||
          relatorio.QtdeNovilhasReavaliar ||
          relatorio.QtdeNovilhasVazias ||
          relatorio.ListaNovilhasGestantes ||
          relatorio.ListaNovilhasReavaliar ||
          relatorio.ListaNovilhasVaziasIATF ||
          relatorio.ListaNovilhasVaziasPGF ||
          relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal ||
          relatorio.ListaNovilhasVaziasTratamentoClinico ||
          relatorio.separarTouroNovilhas ||
          relatorio.OutrasRecomendacoesReprodutivoNovilhas ||
          relatorio.ReprodutivoNovilhasObs ||
          relatorio.Step6Atividade1 ||
          relatorio.Step6Atividade2 ||
          relatorio.Step6Atividade3 ||
          relatorio.Step6Atividade4 ||
          relatorio.Step6Atividade5 ||
          relatorio.Step6OutrasAtividades ||
          relatorio.Step6OutrasAtividadesText ||
          relatorio.Step7Atividade1 ||
          relatorio.Step7Atividade2 ||
          relatorio.Step7Atividade3 ||
          relatorio.Step7Atividade4 ||
          relatorio.Step7Atividade5 ||
          relatorio.Step7OutrasAtividades ||
          relatorio.Step7OutrasAtividadesText
        "
        class="pa-3"
      >
        <v-col cols="12">
          <span class="key-title">Reprodutivo de Vacas </span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeVacasAvaliadas"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Número de vacas avaliadas: </span> <br />
          <span class="value-title">{{
            relatorio.QtdeVacasAvaliadas || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeVacasGestantes"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Número de vacas gestantes: </span> <br />
          <span class="value-title">{{
            relatorio.QtdeVacasGestantes || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeVacasReavaliar"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Número de vacas a reavaliar: </span> <br />
          <span class="value-title">{{
            relatorio.QtdeVacasReavaliar || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeVacasVazias"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Número de vacas vazias: </span> <br />
          <span class="value-title">{{
            relatorio.QtdeVacasVazias || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaVacasGestantes != null &&
            relatorio.ListaVacasGestantes.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Identificação das vacas gestantes </span>
          <br />
          <v-chip
            v-for="(animal, index) in relatorio.ListaVacasGestantes"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoGestante }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaVacasReavaliar != null &&
            relatorio.ListaVacasReavaliar.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Identificação das vacas para reavaliar </span>
          <br />
          <v-chip
            v-for="(animal, index) in relatorio.ListaVacasReavaliar"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoReavaliar }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaVacasVaziasIATF != null &&
            relatorio.ListaVacasVaziasIATF.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das vacas vazias que entraram no protocolo de IATF
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in relatorio.ListaVacasVaziasIATF"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoVaziaIATF }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaVacasVaziasPGF != null &&
            relatorio.ListaVacasVaziasPGF.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das vacas vazias que foram sincronizadas no PGF
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in relatorio.ListaVacasVaziasPGF"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoVaziaPGF }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaVacasVaziasRecuperarCondicaoCorporal != null &&
            relatorio.ListaVacasVaziasRecuperarCondicaoCorporal.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das vacas vazias que precisam recuperar o escore de
            condição corporal
          </span>
          <br />
          <v-chip
            v-for="(
              animal, index
            ) in relatorio.ListaVacasVaziasRecuperarCondicaoCorporal"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoVaziaRecuperarCondicaoCorporal }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaVacasVaziasTratamentoClinico != null &&
            relatorio.ListaVacasVaziasTratamentoClinico.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das vacas vazias que precisam de tratamento clínico
          </span>
          <br />
          <v-chip
            v-for="(
              animal, index
            ) in relatorio.ListaVacasVaziasTratamentoClinico"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoVaziaTratamentoClinico }}
          </v-chip>
        </v-col>

        <v-col
          v-if="
            relatorio.Step6Atividade1 ||
            relatorio.Step6Atividade2 ||
            relatorio.Step6Atividade3 ||
            relatorio.Step6Atividade4 ||
            relatorio.Step6Atividade5
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Atividades realizadas</span>
          <v-col v-if="relatorio.Step6Atividade1" cols="12">
            <span class="value-title">Diagnóstico de gestação das vacas </span>
          </v-col>
          <v-col v-if="relatorio.Step6Atividade2" cols="12">
            <span class="value-title"
              >Treinamento para aumentar a detecção de cio
            </span>
          </v-col>
          <v-col v-if="relatorio.Step6Atividade3" cols="12">
            <span class="value-title"
              >Treinamento para realizar inseminação artificial
            </span>
          </v-col>
          <v-col v-if="relatorio.Step6Atividade4" cols="12">
            <span class="value-title">Treinamento para realizar IATF </span>
          </v-col>
          <v-col v-if="relatorio.Step6Atividade5" cols="12">
            <span class="value-title"
              >Discussão dos indicadores reprodutivos
            </span>
          </v-col>
          <v-col v-if="relatorio.Step6OutrasAtividades" cols="12">
            <span class="key-title">Outras Atividades: </span> <br />
            <span class="value-title">{{
              relatorio.Step6OutrasAtividadesText || '-'
            }}</span>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.SepararTourosVacas != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Recomendações</span>
          <v-col v-if="relatorio.SepararTourosVacas != ''" cols="12">
            <span class="value-title">Separar o touro das vacas </span>
          </v-col>
          <v-col
            v-if="relatorio.OutrasRecomendacoesReprodutivoVacas != ''"
            cols="12"
          >
            <span class="key-title">Outras recomendações: </span> <br />
            <span class="value-title">{{
              relatorio.OutrasRecomendacoesReprodutivoVacas || '-'
            }}</span>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.ReprodutivoVacasObs != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Motivo de não realização dessa atividade:
          </span>
          <br />
          <span class="value-title">{{
            relatorio.ReprodutivoVacasObs || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="
            relatorio.QtdeNovilhasAvaliadas != '' ||
            relatorio.QtdeNovilhasGestantes != '' ||
            relatorio.QtdeNovilhasReavaliar != '' ||
            relatorio.QtdeNovilhasVazias != '' ||
            (relatorio.ListaNovilhasGestantes != null &&
              relatorio.ListaNovilhasGestantes.length != 0) ||
            (relatorio.ListaNovilhasReavaliar != null &&
              relatorio.ListaNovilhasReavaliar.length != 0) ||
            (relatorio.ListaNovilhasVaziasIATF != null &&
              relatorio.ListaNovilhasVaziasIATF.length != 0) ||
            (relatorio.ListaNovilhasVaziasPGF != null &&
              relatorio.ListaNovilhasVaziasPGF.length != 0) ||
            (relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal != null &&
              relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal.length !=
                0) ||
            (relatorio.ListaNovilhasVaziasTratamentoClinico != null &&
              relatorio.ListaNovilhasVaziasTratamentoClinico.length != 0) ||
            relatorio.separarTouroNovilhas != '' ||
            relatorio.OutrasRecomendacoesReprodutivoNovilhas != '' ||
            relatorio.ReprodutivoNovilhasObs != '' ||
            relatorio.Step7Atividade1 ||
            relatorio.Step7Atividade2 ||
            relatorio.Step7Atividade3 ||
            relatorio.Step7Atividade4 ||
            relatorio.Step7Atividade5 ||
            relatorio.Step7OutrasAtividades ||
            relatorio.Step7OutrasAtividadesText
          "
          cols="12"
          class="mt-4"
        >
          <span class="key-title">Reprodutivo de novilhas </span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeNovilhasAvaliadas"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Novilhas avaliadas: </span> <br />
          <span class="value-title">{{
            relatorio.QtdeNovilhasAvaliadas || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeNovilhasGestantes"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Número de novilhas gestantes: </span> <br />
          <span class="value-title">{{
            relatorio.QtdeNovilhasGestantes || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeNovilhasReavaliar"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Número de novilhas a reavaliar: </span>
          <br />
          <span class="value-title">{{
            relatorio.QtdeNovilhasReavaliar || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeNovilhasVazias"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Número de novilhas vazias: </span> <br />
          <span class="value-title">{{
            relatorio.QtdeNovilhasVazias || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaNovilhasGestantes != null &&
            relatorio.ListaNovilhasGestantes.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Identificação das novilhas gestantes </span>
          <br />
          <v-chip
            v-for="(animal, index) in relatorio.ListaNovilhasGestantes"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoNovilhasGestante }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaNovilhasReavaliar != null &&
            relatorio.ListaNovilhasReavaliar.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das novilhas para reavaliar
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in relatorio.ListaNovilhasReavaliar"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoNovilhaReavaliar }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaNovilhasVaziasIATF != null &&
            relatorio.ListaNovilhasVaziasIATF.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das novilhas vazias que entraram no protocolo de IATF
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in relatorio.ListaNovilhasVaziasIATF"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoNovilhaVaziaIATF }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaNovilhasVaziasPGF != null &&
            relatorio.ListaNovilhasVaziasPGF.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das novilhas vazias que foram sincronizadas no PGF
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in relatorio.ListaNovilhasVaziasPGF"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoNovilhasVaziaPGF }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal != null &&
            relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das novilhas vazias que precisam recuperar o escore
            de condição corporal
          </span>
          <br />
          <v-chip
            v-for="(
              animal, index
            ) in relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoNovilhaVaziaRecuperarCondicaoCorporal }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaNovilhasVaziasTratamentoClinico != null &&
            relatorio.ListaNovilhasVaziasTratamentoClinico.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das novilhas vazias que precisam de tratamento
            clínico
          </span>
          <br />
          <v-chip
            v-for="(
              animal, index
            ) in relatorio.ListaNovilhasVaziasTratamentoClinico"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoNovilhaVaziaTratamentoClinico }}
          </v-chip>
        </v-col>

        <v-col
          v-if="
            relatorio.Step7Atividade1 ||
            relatorio.Step7Atividade2 ||
            relatorio.Step7Atividade3 ||
            relatorio.Step7Atividade4 ||
            relatorio.Step7Atividade5 ||
            relatorio.Step7OutrasAtividades
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Atividades realizadas</span>
          <v-col v-if="relatorio.Step7Atividade1" cols="12">
            <span class="value-title"
              >Diagnóstico de gestação das novilhas</span
            >
          </v-col>
          <v-col v-if="relatorio.Step7Atividade2" cols="12">
            <span class="value-title"
              >Treinamento para aumentar a detecção de cio
            </span>
          </v-col>
          <v-col v-if="relatorio.Step7Atividade3" cols="12">
            <span class="value-title"
              >Treinamento para realizar inseminação artificial
            </span>
          </v-col>
          <v-col v-if="relatorio.Step7Atividade4" cols="12">
            <span class="value-title">Treinamento para realizar IATF </span>
          </v-col>
          <v-col v-if="relatorio.Step7Atividade5" cols="12">
            <span class="value-title"
              >Discussão dos indicadores reprodutivos
            </span>
          </v-col>
          <v-col v-if="relatorio.Step7OutrasAtividades" cols="12">
            <span class="key-title">Outras Atividades: </span> <br />
            <span class="value-title">{{
              relatorio.Step7OutrasAtividadesText || '-'
            }}</span>
          </v-col>
        </v-col>

        <v-col
          v-if="
            relatorio.separarTouroNovilhas != '' ||
            relatorio.OutrasRecomendacoesReprodutivoNovilhas
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Recomendações</span>
          <v-col v-if="relatorio.separarTouroNovilhas != ''" cols="12">
            <span class="value-title">Separar o touro das novilhas </span>
          </v-col>
          <v-col
            v-if="relatorio.OutrasRecomendacoesReprodutivoNovilhas"
            cols="12"
          >
            <span class="key-title">Outras recomendações: </span> <br />
            <span class="value-title">{{
              relatorio.OutrasRecomendacoesReprodutivoNovilhas || '-'
            }}</span>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.ReprodutivoNovilhasObs"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Motivo de não realização dessa atividade:
          </span>
          <br />
          <span class="value-title">{{
            relatorio.ReprodutivoNovilhasObs || '-'
          }}</span>
        </v-col>
      </v-row>
      <v-row
        v-else-if="
          relatorio.Reprodutivo &&
          relatorio.vacas &&
          (relatorio.vacas.Vacas ||
            relatorio.vacas.VacasGestantes ||
            relatorio.vacas.VacasReavaliar ||
            relatorio.vacas.VacasVazias ||
            relatorio.vacas.IdentificacaoGestantes ||
            relatorio.vacas.IdentificacaoReavaliar ||
            relatorio.vacas.IdentificacaoIatf ||
            relatorio.vacas.IdentificacaoPgf ||
            relatorio.vacas.IdentificacaoEscore ||
            relatorio.vacas.IdentificacaoClinico ||
            relatorio.vacas.SepararTouros ||
            relatorio.vacas.Obs)
        "
        class="pa-3"
      >
        <v-col cols="12">
          <span class="key-title">Reprodutivo de Vacas </span>
        </v-col>
        <v-col
          v-if="relatorio.vacas.Vacas"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Número de vacas avaliadas: </span> <br />
          <span class="value-title">{{ relatorio.vacas.Vacas || '-' }}</span>
        </v-col>
        <v-col
          v-if="relatorio.vacas.VacasGestantes"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Número de vacas gestantes: </span> <br />
          <span class="value-title">{{
            relatorio.vacas.VacasGestantes || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.vacas.VacasReavaliar"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Número de vacas a reavaliar: </span> <br />
          <span class="value-title">{{
            relatorio.vacas.VacasReavaliar || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.vacas.VacasVazias"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Número de vacas vazias: </span> <br />
          <span class="value-title">{{
            relatorio.vacas.VacasVazias || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.vacas.IdentificacaoGestantes"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Identificação das vacas gestantes </span>
          <br />
          <v-chip
            v-for="(animal, index) in maskIdentify(
              relatorio.vacas.IdentificacaoGestantes,
            )"
            :key="index"
            class="ma-1"
          >
            {{ animal }}
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.vacas.IdentificacaoReavaliar"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Identificação das vacas para reavaliar </span>
          <br />
          <v-chip
            v-for="(animal, index) in maskIdentify(
              relatorio.vacas.IdentificacaoReavaliar,
            )"
            :key="index"
            class="ma-1"
          >
            {{ animal }}
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.vacas.IdentificacaoIatf"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das vacas vazias que entraram no protocolo de IATF
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in maskIdentify(
              relatorio.vacas.IdentificacaoIatf,
            )"
            :key="index"
            class="ma-1"
          >
            {{ animal }}
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.vacas.IdentificacaoPgf"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das vacas vazias que foram sincronizadas no PGF
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in maskIdentify(
              relatorio.vacas.IdentificacaoPgf,
            )"
            :key="index"
            class="ma-1"
          >
            {{ animal }}
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.vacas.IdentificacaoEscore"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das vacas vazias que precisam recuperar o escore de
            condição corporal
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in maskIdentify(
              relatorio.vacas.IdentificacaoEscore,
            )"
            :key="index"
            class="ma-1"
          >
            {{ animal }}
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.vacas.IdentificacaoClinico"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das vacas vazias que precisam de tratamento clínico
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in maskIdentify(
              relatorio.vacas.IdentificacaoClinico,
            )"
            :key="index"
            class="ma-1"
          >
            {{ animal }}
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.vacas.SepararTouros"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Separar o touro das vacas </span>
        </v-col>
        <v-col v-if="relatorio.vacas.Obs" cols="12" class="col-bordered">
          <span class="key-title">Outras recomendações: </span> <br />
          <span class="value-title">{{ relatorio.vacas.Obs || '-' }}</span>
        </v-col>
        <v-col
          v-if="
            relatorio.novilhas.Novilhas ||
            relatorio.novilhas.NovilhasGestantes ||
            relatorio.novilhas.NovilhasReavaliar ||
            relatorio.novilhas.NovilhasVazias ||
            relatorio.novilhas.IdentificacaoGestantes ||
            relatorio.novilhas.IdentificacaoReavaliar ||
            relatorio.novilhas.IdentificacaoIatf ||
            relatorio.novilhas.IdentificacaoPgf ||
            relatorio.novilhas.IdentificacaoEscore ||
            relatorio.novilhas.IdentificacaoClinico ||
            relatorio.novilhas.SepararTouros ||
            relatorio.novilhas.Obs
          "
          cols="12"
          class="mt-4"
        >
          <span class="key-title">Reprodutivo de Recrias </span>
        </v-col>
        <v-col
          v-if="relatorio.novilhas.Novilhas"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Recrias avaliadas: </span> <br />
          <span class="value-title">{{
            relatorio.novilhas.Novilhas || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.novilhas.NovilhasGestantes"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Recrias gestantes: </span> <br />
          <span class="value-title">{{
            relatorio.novilhas.NovilhasGestantes || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.novilhas.NovilhasReavaliar"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Recrias a reavaliar: </span> <br />
          <span class="value-title">{{
            relatorio.novilhas.NovilhasReavaliar || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.novilhas.NovilhasVazias"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Recrias vazias: </span> <br />
          <span class="value-title">{{
            relatorio.novilhas.NovilhasVazias || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.novilhas.IdentificacaoGestantes"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Identificação das recrias gestantes </span>
          <br />
          <v-chip
            v-for="(animal, index) in maskIdentify(
              relatorio.novilhas.IdentificacaoGestantes,
            )"
            :key="index"
            class="ma-1"
          >
            {{ animal }}
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.novilhas.IdentificacaoReavaliar"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das recrias para reavaliar
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in maskIdentify(
              relatorio.novilhas.IdentificacaoReavaliar,
            )"
            :key="index"
            class="ma-1"
          >
            {{ animal }}
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.novilhas.IdentificacaoIatf"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das recrias vazias que entraram no protocolo de IATF
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in maskIdentify(
              relatorio.novilhas.IdentificacaoIatf,
            )"
            :key="index"
            class="ma-1"
          >
            {{ animal }}
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.novilhas.IdentificacaoPgf"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das recrias vazias que foram sincronizadas no PGF
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in maskIdentify(
              relatorio.novilhas.IdentificacaoPgf,
            )"
            :key="index"
            class="ma-1"
          >
            {{ animal }}
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.novilhas.IdentificacaoEscore"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das recrias vazias que precisam recuperar o escore de
            condição corporal
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in maskIdentify(
              relatorio.novilhas.IdentificacaoEscore,
            )"
            :key="index"
            class="ma-1"
          >
            {{ animal }}
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.novilhas.IdentificacaoClinico"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das recrias vazias que precisam de tratamento clínico
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in maskIdentify(
              relatorio.novilhas.IdentificacaoClinico,
            )"
            :key="index"
            class="ma-1"
          >
            {{ animal }}
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.novilhas.SepararTouros"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Separar o touro das recrias </span>
        </v-col>
        <v-col v-if="relatorio.novilhas.Obs" cols="12" class="col-bordered">
          <span class="key-title">Outras recomendações: </span> <br />
          <span class="value-title">{{ relatorio.novilhas.Obs || '-' }}</span>
        </v-col>
      </v-row>
      <v-row v-else-if="relatorio.ReprodutivoObs">
        <v-col>
          <span class="key-title">Atividade não realizada </span> <br />
          <span class="value-title">{{ relatorio.ReprodutivoObs || '-' }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ManejoReprodutivo',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    maskIdentify(raw) {
      return raw ? raw.split('|') : ['Nenhum animal identificado']
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
  line-height: 40px;
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
